<template>
    <Head title="Vendor Credits" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Vendor Credits</span>
        </nav>
    </Teleport>

    <form action="" id="vendor-credits-search-form" class="grid grid-cols-9 gap-x-4 gap-y-6 my-4 items-center">
        <div class="col-span-6 sm:col-span-3">
            <label for="search-vendor-credits" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                </span>

                <input type="search" name="search-vendor-credits" id="search-vendor-credits" v-model="form.search" placeholder="Search" class="input-group-field">
            </div>
        </div>

        <select-input class="col-span-6 md:col-span-1" name="per_page" id="per-page" v-model="form.per_page" placeholder="15" label="Results / Page">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select-input>

        <div class="col-span-6 md:col-span-1 md:pt-6">
            <div class="flex items-center">
                <button type="button" class="btn btn-gray mr-2" @click="$refs.searchFilterSlideOver.show()">
                    <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
                </button>

                <column-selector
                    :headers="headers"
                    :excludedHeaders="excludedHeaders"
                    v-model="form.selected_headers"
                    v-show="props.vendorCredits.data.length"
                />

                <inertia-link :href="route('vendor-credits.index', {remember: 'forget'})" class="btn btn-gray">Clear</inertia-link>
            </div>
        </div>
    </form>

    <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="props.vendorCredits.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-steel-700">Showing {{ props.vendorCredits.from }} - {{ props.vendorCredits.to }} out of {{ props.vendorCredits.total }} Vendor Credits.</div>

        <div class="col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end">
            <div class="flex items-center">
                <inertia-link :href="route('vendor-credits.create')" class="btn btn-blue mr-2">
                    New
                </inertia-link>

                <loading-button :loading="state === 'exporting'" class="btn btn-gray" @click="exportData" :disabled="!props.vendorCredits.data.length">
                    Export to CSV
                </loading-button>
            </div>
        </div>
    </div>

    <div v-if="props.vendorCredits.data.length">
        <div class="overflow-x-auto">
            <transition
                enter-active-class="transition-height ease-in-out duration-500"
                enter-from-class="h-0"
                enter-to-class="h-full"
                leave-active-class="transition-height ease-in-out duration-500"
                leave-from class="h-full"
                leave-to-class="h-0">
                <div v-show="selectedVendorCreditIds.length > 0" class="bg-white flex h-10 items-center sm:left-14">
                    <span v-if="selectedVendorCreditIds.length === 1" class="mr-8">{{  `${selectedVendorCreditIds.length} item selected` }}</span>
                    <span v-else class="mr-8">{{  `${selectedVendorCreditIds.length} items selected` }}</span>

                    <div class="flex space-x-3">
                        <button type="button" @click="resolveSelectedCredits" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyValidCreditsSelected">Resolve</button>
                        <button type="button" @click="unResolveSelectedCredits" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyResolvedCreditsSelected">Un-Resolve</button>
                        <button type="button" @click="rejectSelectedCredits" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyValidCreditsSelected">Reject</button>
                        <button type="button" @click="unRejectSelectedCredits" class="btn btn-gray disabled:cursor-not-allowed disabled:opacity-30" :disabled="!onlyRejectedCreditsSelected">Un-Reject</button>
                    </div>
                </div>
            </transition>

            <table class="table table-condensed" ref="table">
                <thead>
                    <tr>
                        <th scope="col" class="relative px-7 sm:w-12 sm:px-6">
                            <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6" :checked="indeterminate || selectedVendorCreditIds.length === props.vendorCredits.data.length" :indeterminate="indeterminate" @change="selectedVendorCreditIds = $event.target.checked ? props.vendorCredits.data.map((vendorCredit) => vendorCredit.id) : []" />
                        </th>
                        <sortable-table-header field="id" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credits.index">ID</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Vendor Account #')" field="vendor_accounts.account_number" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credits.index">Vendor Account #</sortable-table-header>
                        <th v-show="form.selected_headers.includes('Vendor')" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Vendor</th>
                        <sortable-table-header v-show="form.selected_headers.includes('From Vendor Invoice #')" field="vendor_invoices.invoice_number" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credits.index">From Vendor Invoice #</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Expected')" field="expected_amount" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credits.index">Expected</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Granted')" field="granted_amount" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credits.index">Granted</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Applied')" field="applied_amount" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credits.index">Applied</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Received')" field="received_amount" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credits.index">Received</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Created At')" field="created_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credits.index">Created At</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Resolved At')" field="resolved_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credits.index">Resolved At</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Rejected At')" field="rejected_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credits.index">Rejected At</sortable-table-header>
                        <th v-show="form.selected_headers.includes('Service')" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Service</th>
                        <th v-show="form.selected_headers.includes('Location')" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Location</th>
                        <th v-show="form.selected_headers.includes('Client Company')" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Client Company</th>
                        <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="vendorCredit in props.vendorCredits.data" :key="vendorCredit.id">
                        <td class="relative px-7 sm:w-12 sm:px-6">
                            <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6" :value="vendorCredit.id" v-model="selectedVendorCreditIds" />
                        </td>
                        <td>
                            <span class="inline-block">
                                <inertia-link :href="route('vendor-credits.show', vendorCredit.id)" class="link" title="View This Vendor Credit">
                                    {{ vendorCredit.display_id }}
                                </inertia-link>
                            </span>
                        </td>
                        <td v-show="form.selected_headers.includes('Vendor Account #')">
                            <inertia-link :href="route('vendor-accounts.show', vendorCredit.vendorAccount.id)" class="link" title="View This Vendor Account">
                                {{ vendorCredit.vendorAccount.account_number_normalized }}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('Vendor')">
                            <inertia-link :href="route('vendors.show', vendorCredit.vendorAccount.vendor.id)" class="link" title="View This Vendor">
                                {{ vendorCredit.vendorAccount.vendor.name }}
                            </inertia-link>
                        </td>
                        <td v-show="form.selected_headers.includes('From Vendor Invoice #')">
                            <inertia-link v-if="vendorCredit.originated_from_vendor_invoice_id !== null" :href="route('vendor-invoices.edit', vendorCredit.originated_from_vendor_invoice_id)" class="link" title="View This Vendor Invoice">
                                {{ vendorCredit.originatedFromVendorInvoice.invoice_number }}
                            </inertia-link>
                            <span v-else></span>
                        </td>
                        <td v-show="form.selected_headers.includes('Expected')" class="text-right">{{ $filters.format_money(vendorCredit.expected_amount) }}</td>
                        <td v-show="form.selected_headers.includes('Granted')" class="text-right">{{ vendorCredit.granted_amount ? $filters.format_money(vendorCredit.granted_amount) : '' }}</td>
                        <td v-show="form.selected_headers.includes('Applied')" class="text-right">{{ vendorCredit.applied_amount ? $filters.format_money(vendorCredit.applied_amount) : '' }}</td>
                        <td v-show="form.selected_headers.includes('Received')" class="text-right">{{ vendorCredit.received_amount ? $filters.format_money(vendorCredit.received_amount) : '' }}</td>
                        <td v-show="form.selected_headers.includes('Created At')">{{ $filters.format_date_time(vendorCredit.created_at) }}</td>
                        <td v-show="form.selected_headers.includes('Resolved At')">{{ vendorCredit.resolved_at ? $filters.format_date_time(vendorCredit.resolved_at) : '' }}</td>
                        <td v-show="form.selected_headers.includes('Rejected At')">{{ vendorCredit.rejected_at ? $filters.format_date_time(vendorCredit.rejected_at) : '' }}</td>
                        <td v-show="form.selected_headers.includes('Service')">
                            <inertia-link v-if="vendorCredit.originatedFromLineItemRevision?.lineItem?.service" :href="route('services.show', vendorCredit.originatedFromLineItemRevision.lineItem.service.id)" class="link" title="View This Service">
                                {{ vendorCredit.originatedFromLineItemRevision.lineItem.service.id }}
                            </inertia-link>

                            <span v-else></span>
                        </td>
                        <td v-show="form.selected_headers.includes('Location')">
                            <inertia-link v-if="vendorCredit.originatedFromLineItemRevision?.lineItem?.service" :href="route('locations.show', vendorCredit.originatedFromLineItemRevision.lineItem.service.location.id)" class="link" title="View This Location">
                                {{ vendorCredit.originatedFromLineItemRevision.lineItem.service.location.name }}
                            </inertia-link>

                            <span v-else></span>
                        </td>
                        <td v-show="form.selected_headers.includes('Client Company')">
                            <inertia-link v-if="vendorCredit.originatedFromLineItemRevision?.lineItem?.service" :href="route('client-companies.show', vendorCredit.originatedFromLineItemRevision.lineItem.service.location.clientCompany.id)" class="link" title="View This Client Company">
                                {{ vendorCredit.originatedFromLineItemRevision.lineItem.service.location.clientCompany.name }}
                            </inertia-link>

                            <span v-else></span>
                        </td>
                        <td>
                            <inertia-link :href="route('vendor-credits.show', vendorCredit.id)" class="link" title="View This Vendor Credit">
                                View
                            </inertia-link>

                            <inertia-link v-show=" ! vendorCredit.deleted_at" :href="route('vendor-credits.edit', vendorCredit.id)" class="link ml-2" title="Edit This Vendor Credit">
                                Edit
                            </inertia-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pagination :links="props.vendorCredits.links" />
    </div>

    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
        <icon name="receipt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Vendor Credits Found</span>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #header="{close}">
            <div class="bg-steel-800 border-solid border-b-2 border-orange-600 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form action="" id="credit-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-4 items-center">
                <div class="col-span-1" v-show="form.selected_headers.includes('Created At')">
                    <label class="form-label">Created At</label>

                    <date-input v-model="form.created_at_begin" :errors="errors.created_at_begin" />

                    <span class="block text-center">thru</span>

                    <date-input v-model="form.created_at_end" :errors="errors.created_at_end" class="col-span-1" />
                </div>

                <select-input id="with-trashed" name="with_trashed" class="col-span-6 sm:col-span-1" v-model="form.with_trashed" label="Deleted">
                    <option value="except">Hide</option>
                    <option value="include">Include</option>
                    <option value="only">Only</option>
                </select-input>

                <toggle-switch-input id="hide-resolved" name="hide_resolved" class="col-span-6 sm:col-span-1" v-model="form.hide_resolved" label="Hide Resolved" />

                <toggle-switch-input id="hide-rejected" name="hide_rejected" class="col-span-6 sm:col-span-1" v-model="form.hide_rejected" label="Hide Rejected" />

                <toggle-switch-input id="hide-granted" name="hide_granted" class="col-span-6 sm:col-span-1" v-model="form.hide_granted" label="Hide Granted" />

                <toggle-switch-input id="hide-applied" name="hide_applied" class="col-span-6 sm:col-span-1" v-model="form.hide_applied" label="Hide Applied" />
            </form>
        </template>
    </slide-over>
</template>

<script setup>
    import ColumnSelector from "@/Shared/ColumnSelector.vue";
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from "@/Shared/SelectInput.vue";
    import DateInput from "@/Shared/DateInput.vue";
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import SlideOver from "@/Shared/SlideOver.vue";
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ToggleSwitchInput from "@/Shared/ToggleSwitchInput.vue";
    import { ref, onMounted, reactive, watch, inject, computed } from 'vue';
    import { Head, router } from '@inertiajs/vue3';
    import { pickBy, throttle } from "lodash-es";

    // Inject
    const route = inject('route')

    // Props
    const props = defineProps({
        vendorCredits: {
            type: Object,
            required: true,
        },

        filters: {
            type: Object,
            required: true,
        },

        errors: {
            type: Object,
            required: true,
        },
    });

    // State
    const state = ref('passive')
    const mounted = ref(false)
    const selectedVendorCreditIds = ref([])
    const headers = ref([])
    const excludedHeaders = ref(['ID', 'Actions'])
    const table = ref(null)
    const statusComboBoxQuery = ref('')
    const form = reactive({
        search: props.filters.search,
        per_page: props.filters.per_page,
        selected_headers: props.filters.selected_headers,
        with_trashed: props.filters.with_trashed,
        created_at_begin: props.filters.created_at_begin,
        created_at_end: props.filters.created_at_end,
        hide_resolved: props.filters.hide_resolved,
        hide_rejected: props.filters.hide_rejected,
        hide_granted: props.filters.hide_granted,
        hide_applied: props.filters.hide_applied,
    })

    // Computed
    const indeterminate = computed(() => {
        return selectedVendorCreditIds.value.length > 0 && selectedVendorCreditIds.value.length < props.vendorCredits.data.length
    })

    // Mount
    onMounted(() => {
        mounted.value = true

        if (props.vendorCredits.data.length) {
            getTableHeaders();
        }

        if (form.selected_headers.length === 0) {
            const hiddenHeaders = ['Service', 'Client Company', 'Vendor', 'Location', 'Created At']
            form.selected_headers = headers.value.filter(header => !hiddenHeaders.includes(header))
        }
    })

    // Functions
    function getTableHeaders() {
        const thElements = table.value.querySelectorAll('th'); // Get all the th elements
        headers.value = Array.from(thElements)
            .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
            .map(th => th.textContent)
            .sort((a, b) => a.localeCompare(b));
    }

    function resolveSelectedCredits() {
        if (state.value === 'passive') {
            state.value = 'resolving';

            router.patch(route('vendor-credits.resolve'), {
                vendor_credit_ids: selectedVendorCreditIds.value,
            }, {
                preserveScroll: true,
                onSuccess: () => {
                    selectedVendorCreditIds.value = [];
                    state.value = 'passive';
                },
            })
        }
    }

    function unResolveSelectedCredits() {
        if (state.value === 'passive') {
            state.value = 'unresolving';

            router.patch(route('vendor-credits.unresolve'), {
                vendor_credit_ids: selectedVendorCreditIds.value,
            }, {
                preserveScroll: true,
                onSuccess: () => {
                    selectedVendorCreditIds.value = [];
                    state.value = 'passive';
                },
            })
        }
    }

    function rejectSelectedCredits() {
        if (state.value === 'passive') {
            state.value = 'rejecting';

            router.patch(route('vendor-credits.reject'), {
                vendor_credit_ids: selectedVendorCreditIds.value,
            }, {
                preserveScroll: true,
                onSuccess: () => {
                    selectedVendorCreditIds.value = [];
                    state.value = 'passive';
                },
            })
        }
    }

    function unRejectSelectedCredits() {
        if (state.value === 'passive') {
            state.value = 'unrejecting';

            router.patch(route('vendor-credits.unreject'), {
                vendor_credit_ids: selectedVendorCreditIds.value,
            }, {
                preserveScroll: true,
                onSuccess: () => {
                    selectedVendorCreditIds.value = [];
                    state.value = 'passive';
                },
            })
        }
    }

    function exportData() {
        state.value = 'exporting';
        let query = pickBy(form);
        query = Object.keys(query).length ? query : {remember: 'forget'};

        router.post(route('csv.vendor-credits.index'), query, {
            onFinish: () => { state.value = 'passive'; }
        });
    }

    // Computed
    const onlyValidCreditsSelected = computed(() => {
        if (selectedVendorCreditIds.value.length > 0) {
            return selectedVendorCreditIds.value.every(id => {
                return props.vendorCredits.data.find(credit => credit.id === id).rejected_at === null
                    && props.vendorCredits.data.find(credit => credit.id === id).resolved_at === null;
            })
        }
    })

    const onlyRejectedCreditsSelected = computed(() => {
        if (selectedVendorCreditIds.value.length > 0) {
            return selectedVendorCreditIds.value.every(id => {
                return props.vendorCredits.data.find(credit => credit.id === id).rejected_at !== null;
            })
        }
    })

    const onlyResolvedCreditsSelected = computed(() => {
        if (selectedVendorCreditIds.value.length > 0) {
            return selectedVendorCreditIds.value.every(id => {
                return props.vendorCredits.data.find(credit => credit.id === id).resolved_at !== null;
            })
        }
    })

    // Watch
    watch(form, throttle(function() {
        let query = pickBy(form);
        query = Object.keys(query).length ? query : {remember: 'forget'};

        router.get(route('vendor-credits.index'), query, {preserveState: true, preserveScroll: true});
    }, 150), {deep: true})

</script>