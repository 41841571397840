<template>
    <Head title="Plan" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Plan</span>
        </nav>
    </Teleport>

    <div class="relative">
        <div class="bg-white rounded-lg overflow-hidden">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <sub-nav current-tab="features" />

                <div class="lg:col-span-9 pt-8">

                    <div class="sm:px-6">
                        <template v-if="$page.props.tenant_billing_type !== 'custom'">
                            <h2 class="text-lg leading-6 font-medium text-gray-900">Current Plan</h2>

                            <div v-if="$page.props.tenant_billing_type === 'trial'">
                                <p class="mt-1 mb-3 text-sm">
                                    You're on a trial. Select from one of the plan options below blah blah blah.
                                </p>

                                <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                    <li v-for="plan in plans" :key="plan.id" :value="plan.id" class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                                        <div class="flex w-full items-center justify-between space-x-6 p-6">
                                            <div class="flex-1 truncate">
                                                <div class="flex items-center space-x-3">
                                                    <h3 class="truncate text-sm font-medium text-gray-900">{{ plan.plan }}</h3>
                                                </div>
                                                <p class="mt-1 truncate text-sm text-gray-500">{{ plan.description }}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="-mt-px flex grow">
                                                <button type="button" class="btn btn-orange" @click="newSubscription(plan)">Signup</button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                            <div v-else-if="$page.props.tenant_billing_type === 'pay-as-you-go'">
                                <p class="mt-1 text-sm text-gray-500">
                                    Here is your current billing subscription.
                                </p>
                                <p class="mb-2">Gather Plan with Pay-as-you-go pricing tier ($1.00 per invoice)</p>
                                <p>
                                    Current Balance: {{ $filters.format_money(tenantBillingConfiguration.current_balance) }}
                                </p>
                                <p>
                                    When balance goes below: {{ $filters.format_money(tenantBillingConfiguration.balance_floor) }}
                                </p>
                                <p>
                                    Bring my balance back up to: {{ $filters.format_money(tenantBillingConfiguration.balance_ceiling) }}
                                </p>
                                <p>
                                    Using this payment method: {{ tenantBillingConfiguration.default_payment_method }}
                                </p>
                                    <button type="button" class="btn btn-orange" @click="stripeCustomerPortal()"> Stripe Customer Portal </button>
                            </div>

                            <hr class="my-12">
                        </template>

                        <div>
                            <h2 class="text-lg leading-6 font-medium text-gray-900">Features</h2>
                            <p class="mt-1 text-sm text-gray-500">
                                These are the current features available for your plan.
                            </p>
                        </div>

                        <div class="py-5">
                            <ul role="list" class="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                                <li class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
                                    <div class="flex flex-1 flex-col px-1 py-8">
                                        <h3 class="mt-6 mb-6 text-lg font-medium text-gray-900">Gather</h3>
                                        <dl class="mt-1 flex flex-grow flex-col justify-between">
                                            <span v-if="features['custom_data_fields'] === true" class="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                                                <icon name="check" class="inline fill-current text-green-600 w-4 h-4 mx-1" />
                                                Custom Data Fields
                                            </span>
                                            <span v-else class="rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
                                                <icon name="lock" class="inline fill-current text-red-600 w-4 h-4 mx-1" />
                                                Custom Data Fields
                                            </span>

                                            <span v-if="features['vendor_invoice_processing'] === true" class="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                                                <icon name="check" class="inline fill-current text-green-600 w-4 h-4 mx-1" />
                                                Vendor Invoice Processing
                                            </span>
                                            <span v-else class="rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
                                                <icon name="lock" class="inline fill-current text-red-600 w-4 h-4 mx-1" />
                                                Vendor Invoice Processing
                                            </span>

                                            <span v-if="features['vendor_invoice_gathering'] === true" class="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                                                <icon name="check" class="inline fill-current text-green-600 w-4 h-4 mx-1" />
                                                Vendor Invoice Gathering
                                            </span>
                                            <span v-else class="rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
                                                <icon name="lock" class="inline fill-current text-red-600 w-4 h-4 mx-1" />
                                                Vendor Invoice Gathering
                                            </span>
                                        </dl>
                                    </div>
                                </li>

                                <li class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
                                    <div class="flex flex-1 flex-col px-1 py-8">
                                        <h3 class="mt-6 mb-6 text-lg font-medium text-gray-900">Extract</h3>
                                        <dl class="mt-1 flex flex-grow flex-col justify-between">
                                            <span v-if="features['vendor_invoice_extraction'] === true" class="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                                                <icon name="check" class="inline fill-current text-green-600 w-4 h-4 mx-1" />
                                                Vendor Invoice Extraction
                                            </span>
                                            <span v-else class="rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
                                                <icon name="lock" class="inline fill-current text-red-600 w-4 h-4 mx-1" />
                                                Vendor Invoice Extraction
                                            </span>
                                        </dl>
                                    </div>
                                </li>

                                <li class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
                                    <div class="flex flex-1 flex-col px-1 py-8">
                                        <h3 class="mt-6 mb-6 text-lg font-medium text-gray-900">Audit</h3>
                                        <dl class="mt-1 flex flex-grow flex-col justify-between">
                                            <span v-if="features['vendor_invoice_auditing'] === true" class="rounded-full bg-green-100 px-2 my-1 py-1 text-xs font-medium text-green-800">
                                                <icon name="check" class="inline fill-current text-green-600 w-4 h-4 mx-1" />
                                                Vendor Invoice Auditing
                                            </span>
                                            <span v-else class="rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
                                                <icon name="lock" class="inline fill-current text-red-600 w-4 h-4 mx-1" />
                                                Vendor Invoice Auditing
                                            </span>

                                            <span v-if="features['sustainability_reporting'] === true" class="rounded-full bg-green-100 px-2 my-1 py-1 text-xs font-medium text-green-800">
                                                <icon name="check" class="inline fill-current text-green-600 w-4 h-4 mx-1" />
                                                Sustainability Reporting
                                            </span>
                                            <span v-else class="rounded-full bg-red-100 px-2 my-1 py-1 text-xs font-medium text-red-800">
                                                <icon name="lock" class="inline fill-current text-red-600 w-4 h-4 mx-1" />
                                                Sustainability Reporting
                                            </span>

                                            <span v-if="features['vendor_contract_auto_cancellation'] === true" class="rounded-full bg-green-100 px-2 my-1 py-1 text-xs font-medium text-green-800">
                                                <icon name="check" class="inline fill-current text-green-600 w-4 h-4 mx-1" />
                                                Vendor Contract Auto Cancellation
                                            </span>
                                            <span v-else class="rounded-full bg-red-100 px-2 my-1 py-1 text-xs font-medium text-red-800">
                                                <icon name="lock" class="inline fill-current text-red-600 w-4 h-4 mx-1" />
                                                Vendor Contract Auto Cancellation
                                            </span>
                                            <span v-if="features['vendor_credit_tracking'] === true" class="rounded-full bg-green-100 px-2 my-1 py-1 text-xs font-medium text-green-800">
                                                <icon name="check" class="inline fill-current text-green-600 w-4 h-4 mx-1" />
                                                Vendor Credit Tracking
                                            </span>
                                            <span v-else class="rounded-full bg-red-100 px-2 my-1 py-1 text-xs font-medium text-red-800">
                                                <icon name="lock" class="inline fill-current text-red-600 w-4 h-4 mx-1" />
                                                Vendor Credit Tracking
                                            </span>
                                        </dl>
                                    </div>
                                </li>

                                <li class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
                                    <div class="flex flex-1 flex-col px-1 py-8">
                                        <h3 class="mt-6 mb-6 text-lg font-medium text-gray-900">Revenue</h3>
                                        <dl class="mt-1 flex flex-grow flex-col justify-between">
                                            <span v-if="features['revenue_management'] === true" class="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                                                <icon name="check" class="inline fill-current text-green-600 w-4 h-4 mx-1" />
                                                Revenue Management
                                            </span>
                                            <span v-else class="rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
                                                <icon name="lock" class="inline fill-current text-red-600 w-4 h-4 mx-1" />
                                                Revenue Management
                                            </span>
                                        </dl>
                                    </div>
                                </li>

                                <li class="col-span-4 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
                                    <div class="flex flex-1 align-self-auto flex-col  p-2">
                                        <h3 class="mt-6 mb-6 text-lg font-medium text-gray-900">
                                            Addons
                                            <button type="button" @click="expandAddons">
                                                <icon class="inline h-4 w-4 fill-current cursor-pointer" :name="addons_collapsed === true ?  'chevron-down' : 'chevron-right'" />
                                            </button>
                                        </h3>

                                        <dl v-if="addons_collapsed === true" class="mt-1 flex flex-grow flex-col justify-between">
                                            <span v-if="features['api_access'] === true" class="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                                                <icon name="check" class="inline fill-current text-green-600 w-4 h-4 mx-1" />
                                                API Access
                                            </span>

                                            <span v-else class="rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
                                                <icon name="lock" class="inline fill-current text-red-600 w-4 h-4 mx-1" />
                                                API Access
                                            </span>
                                            <span v-if="features['client_portal'] === true" class="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                                                <icon name="check" class="inline fill-current text-green-600 w-4 h-4 mx-1" />
                                                Client Portal
                                            </span>

                                            <span v-else class="rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
                                                <icon name="lock" class="inline fill-current text-red-600 w-4 h-4 mx-1" />
                                                Client Portal
                                            </span>
                                            <span v-if="features['compactor_monitors'] === true" class="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                                                <icon name="check" class="inline fill-current text-green-600 w-4 h-4 mx-1" />
                                                Compactor Monitors
                                            </span>
                                            <span v-else class="rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
                                                <icon name="lock" class="inline fill-current text-red-600 w-4 h-4 mx-1" />
                                                Compactor Monitors
                                            </span>
                                        </dl>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue';

    // Components
    import {Head, router} from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import SubNav from '../SubNav.vue';
    import { filters } from '@/Shared/Utils/Filters.js';

    // Props
    const props = defineProps({
        features: Object,
        tenant: Object,
        plans: Object,
        tenantBillingConfiguration: Object,
    });

    // State
    const addons_collapsed = ref(false);
    const mounted = ref(false);

    onMounted(() => {
        mounted.value = true;
    });

    function expandAddons() {
        addons_collapsed.value = !addons_collapsed.value
    }

    function newSubscription(plan) {
        switch (plan.tier) {
            case 'Pay As You Go':
                router.get(route('tenant-settings.pay-as-you-go.create'));
                break;
            default:
                // router.post(route('tenant-settings.subscriptions.new', plan.id));
                console.log('default');
        }
    }

    function stripeCustomerPortal() {
        router.post(route('tenant-settings.subscriptions.portal'));
    }
</script>