<template>
    <Head title="Create Vendor" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendors.index')" class="breadcrumb-link">Vendors</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <nav aria-label="Progress">
        <ol role="list" class="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
            <li v-for="(step, stepIdx) in steps" :key="step.name" class="relative md:flex-1 md:flex">
                <a v-if="step.status === 'complete'" :href="step.href" class="group flex items-center w-full"
                   @click="transitionActiveStep(stepIdx+1)">
                    <span class="px-6 py-4 flex items-center text-sm font-medium">
                        <span
                            class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-d-blue-500 rounded-full group-hover:bg-d-blue-600">
                            <icon name="check" class="w-5 h-5 fill-current text-white" aria-hidden="true"/>
                        </span>
                    <span class="ml-4 text-sm font-medium text-gray-900">{{ step.name }}</span>
                  </span>
                </a>
                <a v-else-if="step.status === 'current'" :href="step.href"
                   class="px-6 py-4 flex items-center text-sm font-medium" aria-current="step"
                   @click="transitionActiveStep(stepIdx+1)">
                    <span
                        class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-d-blue-500 rounded-full">
                         <span class="text-d-blue-600">{{ step.id }}</span>
                    </span>
                    <span class="ml-4 text-sm font-medium text-d-blue-500">{{ step.name }}</span>
                </a>
                <a v-else :href="step.href" class="group flex items-center"
                   @click="transitionActiveStep(stepIdx+1)">
                    <span class="px-6 py-4 flex items-center text-sm font-medium">
                        <span
                            class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                            <span class="text-gray-500 group-hover:text-gray-900">{{ step.id }}</span>
                        </span>
                    <span class="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{{
                            step.name
                        }}</span>
                    </span>
                </a>
                <template v-if="stepIdx !== steps.length - 1">
                    <!-- Arrow separator for lg screens and up -->
                    <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                        <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none"
                             preserveAspectRatio="none">
                            <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor"
                                  stroke-linejoin="round"/>
                        </svg>
                    </div>
                </template>
            </li>
        </ol>
    </nav>

    <form class="mt-8 mb-12" @submit.prevent="submitForm">
        <fieldset class="space-y-8 sm:space-y-5">
            <div v-show="activeStep === 1" class="space-y-6">
                <div :key="vendorTemplatesFiltered" class="grid grid-cols-1 sm:grid-cols-3 gap-x-4 gap-y-6 my-1">

                    <search-input v-model="form.vendor_template_id"
                                  display-property="name"
                                  value-property="id"
                                  route="json.vendor-templates.index"
                                  :errors="errors.vendor_template_id"
                                  :default-value="vendorTemplate"
                                  help-text="Select from a list of existing vendors. Otherwise, you can skip this step."
                                  @selected="vendorTemplateSelected"
                                  label="Vendor">
                        <template #searchResult="obj">
                            <span class="block mb-1">{{ obj.result.name }}</span>
                            <div class="inline">
                                <span class=" text-sm font-bold"> {{
                                    JSON.parse(obj.result.physical_address).city
                                }}, </span>
                                <span class="text-sm">{{ JSON.parse(obj.result.physical_address).state }}</span>
                            </div>
                        </template>
                    </search-input>
                </div>

                <div class="flex w-full mt-8 flex-row">
                    <a class="btn btn-gray" :class="stepLinkEnabled(activeStep+1)"
                       @click="transitionActiveStep(activeStep+1)">
                        <span class="mr-2">{{ form.vendor_template_id ? 'Next Step' : 'Skip' }}</span>
                        <icon name="long-arrow-right" class="inline fill-current w-4 h-4"/>
                    </a>
                </div>
            </div>
            <div v-show="activeStep === 2" class="space-y-6">

                <fieldset class="grid lg:grid-cols-4">
                    <legend class="text-lg leading-6 font-medium text-gray-900">Basic Info</legend>

                    <div class="m-2 my-4 gap-y-6">
                        <Combobox as="div" v-model="form.service_type_offering_ids" multiple>
                            <ComboboxLabel class="form-label" for="service_type_offering_ids">Service Offerings
                            </ComboboxLabel>

                            <div class="relative">
                                <ComboboxInput id="service_type_offering_ids" name="service_type_offering_ids"
                                               class="form-input"
                                               @change="serviceTypeOfferingComboBoxQuery = $event.target.value"
                                               :display-value="comboDisplayValue" autocomplete="off"/>
                                <ComboboxButton
                                    class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <!-- Heroicon name: solid/selector -->
                                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </ComboboxButton>

                                <ComboboxOptions v-if="filteredServiceTypeOfferings.length > 0"
                                                 class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ComboboxOption v-for="offering in filteredServiceTypeOfferings"
                                                    :key="offering.id"
                                                    :value="offering.id" as="template"
                                                    v-slot="{ active, selected }">
                                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                            <span :class="['block truncate', selected && 'font-semibold']">{{
                                                    offering.name
                                                }}</span>

                                            <span v-if="selected"
                                                  :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                             fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                    </span>
                                        </li>
                                    </ComboboxOption>
                                </ComboboxOptions>
                            </div>
                        </Combobox>
                    </div>

                    <text-input v-model="form.name" label="Name" placeholder="Name" class="m-2 my-4 gap-y-6"
                                :errors="errors.name" mark-as-required></text-input>

                    <select-input class="m-2 my-4 gap-y-6"
                                  v-model="form.has_gps_tracking_on_trucks" label="GPS Tracking on Trucks?"
                                  :errors="errors.has_gps_tracking_on_trucks">
                        <option :value="null">Unsure</option>
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                    </select-input>

<!--                    To be used when we implement vendor credit requests...-->
<!--                    <div class="m-2 my-4 gap-y-6 flex items-center">-->
<!--                        <toggle-switch-input-->
<!--                            v-if="autoCreateCreditRequests"-->
<!--                            label="Create Credit Requests Per Invoice"-->
<!--                            v-model="form.create_request_per_invoice"-->
<!--                            class="m-2 gap-y-6"-->
<!--                            :errors="errors.create_request_per_invoice"-->
<!--                        />-->
<!--                    </div>-->
                </fieldset>

                <fieldset class="grid lg:grid-cols-4">
                    <legend class="text-lg leading-6 font-medium text-gray-900">Contact Information</legend>
                    <text-input class="m-2 my-4 gap-y-6"
                                v-model="form.general_email_address" label="General Email Address"
                                placeholder="general_email_address" :errors="errors.general_email_address"/>
                    <text-input class="m-2 my-4 gap-y-6"
                                v-model="form.general_phone_number" label="General Phone #"
                                placeholder="general_phone_number" :errors="errors.general_phone_number"/>
                    <text-input class="m-2 my-4 gap-y-6"
                                v-model="form.general_fax_phone_number" label="General Fax Phone #"
                                placeholder="general_fax_phone_number" :errors="errors.general_fax_phone_number"/>
                    <text-input v-model="form.check_remittance_address"
                                label="Check Remittance Address"
                                class="m-2 my-4 gap-y-6"
                                placeholder="check_remittance_address"
                                :errors="errors.check_remittance_address"
                    />

                    <text-input v-model="form.legal_mailing_address"
                                label="Legal Mailing Address"
                                class="m-2 my-4 gap-y-6"
                                placeholder="legal_mailing_address"
                                :errors="errors.legal_mailing_address"
                    />
                </fieldset>

                <fieldset class="grid lg:grid-cols-4">
                    <legend class="text-lg leading-6 font-medium text-gray-900">Other Information</legend>
                    <text-input v-model="form.external_reference_id" label="External Reference Id"
                                class="m-2 mt-4 gap-y-6" :errors="errors.external_reference_id"/>
                    <select-input v-model="form.external_accounting_id" label="External Accounting Id"
                                  :errors="errors.external_accounting_id"
                                  placeholder="Please select an external accounting vendor"
                                  class="m-2 mt-4 gap-y-6">
                        <option v-for="externalAccountingVendor in sortedExternalAccountingVendors"
                                :key="externalAccountingVendor.id"
                                :value="externalAccountingVendor.external_accounting_id">
                            {{ externalAccountingVendor.name }}
                        </option>
                    </select-input>
                    <text-input class="m-2 mt-4 gap-y-6"
                                v-model="form.work_order_request_email_address"
                                label="Work Order Request Email Address"
                                placeholder="Work Order Request Email Address"
                                :errors="errors.work_order_request_email_address"/>
                </fieldset>

                <address-picker
                    v-model="form.physical_address"
                    legend="Physical Address"
                    description="The physical address of this vendor (including lat/long information if available)."
                    class="mt-6"
                    :errors="$filterErrorsByPrefix(errors, 'physical_address')"
                />
                <div class="w-full flex flex-wrap">
                    <loading-button :loading="state === 'saving'" class="btn btn-orange">
                        Save
                    </loading-button>
                </div>
            </div>
        </fieldset>
    </form>
</template>

<script>
    import { Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import AddressPicker from '@/Shared/AddressPicker.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import QuillEditor from '@/Shared/QuillEditor.vue';
    import ToggleSwitchInput from "../../Shared/ToggleSwitchInput.vue";
    import axios from "axios";
    import SearchInput from "../../Shared/SearchInput.vue";

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';

    export default {
        components: {
            Icon,
            AddressPicker,
            LoadingButton,
            Pagination,
            SelectInput,
            TextInput,
            QuillEditor,
            ToggleSwitchInput,
            SearchInput,
            Head,

            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({}),
            },

            vendor: {
                type: Object,
                required: true
            },
            
            externalAccountingVendors: {
                type: Array,
                required: true
            },

            serviceTypeOfferings: {
                type: Array,
                required: true
            },

            tenant: {
                type: Object,
                required: true
            },

            parentVendorCompanies: {
                type: Array,
                required: true
            },

            autoCreateCreditRequests: {
                type: Boolean,
                required: true
            },
        },

        data() {
            return {
                form: {
                    parent_vendor_company_id: null,
                    service_type_offering_ids: [],
                    vendor_template_id: null,
                    create_request_per_invoice: true,
                },
                parentVendorCompanyId: null,
                state: 'passive',
                serviceTypeOfferingComboBoxQuery: '',
                invoiceType: null,
                vendorTemplate: null,
                vendorCompanyVendorTemplates: this.vendorTemplates,
                showEditWarning: false,
                stateCode: null,
                vendorTemplates: [],
                vendorTemplatesFiltered: 0,
                steps: [
                    {id: 1, name: 'Vendor', href: '#', status: 'current'},
                    {id: 2, name: 'Vendor Details', href: '#', status: 'upcoming'},
                ],
                activeStep: 1,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm(e) {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    this.$inertia
                        .post(this.$route('vendors.store'), this.form, {
                            onFinish: () => this.state = 'passive'
                        });
                }
            },

            comboDisplayValue(options) {
                if (Array.isArray(options)) {
                    if (options.length > 1) {
                        return `${options.length} offerings selected`;
                    } else if (options.length === 1) {
                        return '1 offering selected';
                    } else {
                        return 'No offerings selected';
                    }
                }
            },

            vendorTemplateSelected() {
                axios.get(this.$route('json.vendor-templates.show', this.form.vendor_template_id)).then((response) => {
                    this.form = {
                        ...response.data,
                        physical_address: JSON.parse(response.data.physical_address),
                        invoice_type: response.data.invoice_type,
                        external_accounting_id: this.form.external_accounting_id,
                        external_reference_id: this.form.external_reference_id,
                    }

                    this.form.vendor_template_id = response.data.id;

                    this.stateCode = JSON.parse(response.data.physical_address).state_code;
                    this.vendorTemplate = {...response.data};
                    this.vendorTemplatesFiltered++;
                });

                axios.get(this.$route('json.vendor-templates.service-type-offerings.index', this.form.vendor_template_id)).then((response) => {
                    this.form.service_type_offering_ids = response.data;
                });

            },

            removeVendorTemplate() {
                if (window.confirm('Are you sure you want to remove this vendor template?')) {
                    this.form.vendor_template_id = null;
                    this.vendorTemplate = null;
                    this.stateCode = null;
                    this.vendorTemplatesFiltered++;
                }
            },
            transitionActiveStep(stepNumber) {
                if (this.canTransitionToStep((stepNumber))) {
                    this.activeStep = stepNumber;
                }
            },

            canTransitionToStep(stepNumber) {
                switch (stepNumber) {
                    case 1:
                        return true;
                    case 2:
                        return true;
                    default:
                        return false;
                }
            },

            stepLinkEnabled(newStepNumber) {
                if (!this.canTransitionToStep(newStepNumber)) {
                    return 'disabled';
                }

                return 'cursor-pointer';
            },
        },
        watch: {
            activeStep(newValue, oldValue) {
                this.steps = this.steps.map(function (step) {
                    if (step.id < newValue) {
                        step.status = 'complete';
                    } else if (step.id === newValue) {
                        step.status = 'current';
                    } else {
                        step.status = 'upcoming';
                    }
                    return step;
                })
            },
        },

        computed: {
            filteredServiceTypeOfferings() {
                return this.serviceTypeOfferingComboBoxQuery === ''
                    ? this.serviceTypeOfferings
                    : this.serviceTypeOfferings.filter((offering) => {
                        return offering.name.toLowerCase().includes(this.serviceTypeOfferingComboBoxQuery.toLowerCase());
                    });
            },

            sortedExternalAccountingVendors() {
                return this.externalAccountingVendors?.sort((a,b) => a.name.localeCompare(b.name));
            },
        }
    }
</script>
