<template>
    <Head title="Edit - Extracted Vendor Invoice" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('ocr-vendor-invoices.index')" class="breadcrumb-link">Extracted Invoices</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="flex flex-wrap justify-between items-center my-4">
        <horizontal-sub-nav
            :permissions="$page.props.permissions"
            current-tab="extracted-invoice"
            :vendor-invoice-id="ocrVendorInvoice?.vendorInvoice?.id ?? null"
            :ocr-vendor-invoice-id="ocrVendorInvoice.id"
            :gathered-vendor-invoice-file-id="ocrVendorInvoice.gatheredVendorInvoiceFile.id"
        />

        <div v-if="otherUsers.length > 0" class="flex -space-x-2 overflow-hidden mb-1">
            <template v-for="user in otherUsers" :key="user.id">
                <img v-if="user.avatar_thumbnail_url" :src="user.avatar_thumbnail_url" class="inline-block h-10 w-10 rounded-full ring-2 ring-white" :alt="user.name">
                <span v-else class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 mr-2">
                    <span class="text-lg font-medium leading-none text-white uppercase">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
                </span>
            </template>
        </div>
    </div>

    <div v-if="props.isProcessing" class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8 rounded-md bg-blue-50 p-4 my-3">
        <div class="flex">
            <div class="flex-shrink-0">
                <!-- Heroicon name: solid/information-circle -->
                <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-blue-700">
                    This invoice is currently being processed.  Please wait a moment for the processing to complete.
                </p>
            </div>
        </div>
    </div>

    <!-- Error Display -->
    <div v-if="Object.keys(combinedErrors).length > 0 && !ocrVendorInvoice.archived_at">
        <div class="mt-2 mb-2 mx-4 text-gray-700">This extracted invoice is not ready for auditing. Please resolve issues.</div>

        <div class="bg-red-100 rounded shadow-md p-4">
            <span class="font-semibold text-xl">Issues:</span>

            <p v-for="(validation_errors, key) in combinedErrors" :key="key">
               {{ validation_errors }}
            </p>
        </div>
    </div>

    <template v-if="ocrVendorInvoice.archived_at">
        <div class="flex flex-row-reverse my-4">
            <loading-button type="button" :disabled="state !== 'passive'" :loading="state === 'unarchiving'" @click="unarchiveOCRVendorInvoice" class="btn btn-gray mr-2">Unarchive</loading-button>

            <div class="flex-grow rounded-md bg-blue-50 p-2 mr-8">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <icon name="info-circle" class="h-6 w-6 text-blue-400 fill-current" />
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between">
                        <p class="text-blue-700">This invoice has been archived. No actions can be taken at this time. If edits are necessary, please unarchive the invoice.</p>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <!-- Warning Display -->
        <div v-if="possibleDuplicateOCRVendorInvoiceId && ocrVendorInvoice.status !== 'Processing Complete'" class="bg-yellow-100 rounded shadow-md p-4 my-4">
            <span class="font-semibold text-xl">Possible Duplicate Invoice Detected</span>

            <p>
                This Invoice was tagged as a possible duplicate of vendor invoice document <a target="blank" :href="$route('ocr-vendor-invoices.edit', possibleDuplicateOCRVendorInvoiceId)" class="link">#{{ possibleDuplicateOCRVendorInvoiceId }}</a>. If this is NOT a duplicate invoice, ignore this message.
            </p>

            <div v-if="props.ocrVendorInvoice.status !== 'Marked As Duplicate'" class="items-center inline-flex mt-4">
                <loading-button :disabled="state !== 'passive'" :loading="state === 'marking-ocr-vendor-invoice-as-duplicate'" @click="markOCRVendorInvoiceAsDuplicate" class="btn btn-gray mr-4">
                    Mark as duplicate
                </loading-button>
            </div>
        </div>

        <div class="flex flex-row-reverse my-4">
            <loading-button v-if="props.invoiceCanBeDeleted" :loading="state === 'deleting'" @click.prevent="deleteInvoice" class="btn btn-red mr-2">
                Delete
            </loading-button>

            <loading-button type="button" :disabled="state !== 'passive'" :loading="state === 'archiving'" @click="archiveOCRVendorInvoice" class="btn btn-gray mr-2">
                Archive
            </loading-button>

            <loading-button type="button" v-if="props.ocrVendorInvoice.status === 'Marked As Duplicate'" :disabled="state !== 'passive'" :loading="state === 'unmarking-ocr-vendor-invoice-as-duplicate'" @click="unmarkOCRVendorInvoiceAsDuplicate" class="btn btn-gray mr-2">
                Unmark as duplicate
            </loading-button>

            <template v-if="$page.props.permissions.auditVendorInvoices && invoiceIsWritable && !$page.props.tenant.vendor_invoice_settings.auto_process_into_vendor_invoice">
                <loading-button type="button" :disabled="state !== 'passive'" :loading="state === 'processing'" v-if="!ocrVendorInvoice?.vendorInvoice && Object.keys(props.auditingValidationErrors).length === 0" @click="processOCRVendorInvoice" class="btn btn-gray mr-2">Process</loading-button>
                <loading-button type="button" :disabled="state !== 'passive'" :loading="state === 'processing'" v-else-if="Object.keys(props.auditingValidationErrors).length === 0" @click="reprocessOCRVendorInvoice" class="btn btn-gray mr-2">Reprocess</loading-button>
            </template>

            <loading-button
                v-if="invoiceIsWritable"
                type="button"
                :loading="state === 'saving'"
                :disabled="state !== 'passive'"
                @click.prevent="updateOCRVendorInvoice"
                class="btn btn-orange mr-2">
                Save
            </loading-button>
        </div>
    </template>

    <div v-if="!ocrVendorInvoice.gatheredVendorInvoiceFile.bulk_import_id" class="grid grid-cols-3">
        <div class="col-span-1 overflow-scroll" style="height: 80vh;">
            <form class="px-2 py-6 grid grid-cols-1 gap-x-4 gap-y-6">
                <div>
                    <label class="form-label">Status</label>

                    <span class="mr-4" :class="{'text-green-600': ocrVendorInvoice.status === 'Sent To Audit'}">
                        {{  ocrVendorInvoice.status }}
                    </span>
                </div>

                <div>
                    <label class="form-label">Vendor Account</label>
                    <a v-if="form.vendorAccount" class="m-1 link text-sm" target="_blank" :href="$route('vendor-accounts.show', form.vendorAccount.id)">{{ form.vendorAccount.vendor?.name ?? '' }} | {{ form.vendorAccount.account_number }}</a>
                    <span v-else>No vendor account is associated</span>
                </div>

                <search-input :markAsRequired="true" @selected="selectedAccountNumber" :disabled="!invoiceCanBeUpdated" label="Account Number" v-model="form.account_number" :default-value="{account_number: form.account_number}" :errors="combinedErrors.account_number" route="json.vendor-accounts.index" value-property="account_number" display-property="account_number">
                    <template #searchResult="obj">
                        <span class="block mb-1">Account Number: {{ obj.result.account_number }}</span>
                        <span class="block text-sm font-bold" v-if="obj.result.status !== 'Active'">{{ obj.result.status }}</span>
                        <span class="text-sm">{{ obj.result.vendor?.name  ?? '' }}</span>
                    </template>
                </search-input>

                <text-input :markAsRequired="true" :disabled="!invoiceCanBeUpdated" label="Invoice Number" v-model="form.invoice_number" :errors="combinedErrors.invoice_number" />
                <date-input :markAsRequired="true" :disabled="!invoiceCanBeUpdated" label="Invoice Date" v-model="form.invoice_date" :errors="combinedErrors.invoice_date" />
                <date-input :disabled="!invoiceCanBeUpdated" label="Due Date" v-model="form.due_date" :errors="combinedErrors.due_date" />
                <money-input :markAsRequired="true" :defaultCurrency="$page.props.currency" :disabled="!invoiceCanBeUpdated" label="Invoice Total" v-model="form.invoice_total" :errors="combinedErrors.invoice_total" />
                <money-input :markAsRequired="true" :defaultCurrency="$page.props.currency" :disabled="!invoiceCanBeUpdated" label="Amount Due" v-model="form.amount_due" :errors="combinedErrors.amount_due" />
                <date-input :disabled="!invoiceCanBeUpdated" label="Service Start Date" v-model="form.service_start_date" :errors="combinedErrors.service_start_date" />
                <date-input :disabled="!invoiceCanBeUpdated" label="Service End Date" v-model="form.service_end_date" :errors="combinedErrors.service_end_date" />
                <money-input :disabled="!invoiceCanBeUpdated" :defaultCurrency="$page.props.currency" label="Previous Unpaid Balance" v-model="form.previous_unpaid_balance" :errors="combinedErrors.previous_unpaid_balance" />
                <money-input :disabled="!invoiceCanBeUpdated" :defaultCurrency="$page.props.currency" label="Subtotal" v-model="form.subtotal" :errors="combinedErrors.subtotal" />
                <money-input :disabled="!invoiceCanBeUpdated" :defaultCurrency="$page.props.currency" label="Total Tax" v-model="form.total_tax" :errors="combinedErrors.total_tax" />
                <text-input :disabled="!invoiceCanBeUpdated" label="Purchase Order" v-model="form.purchase_order" :errors="combinedErrors.purchase_order" />
                <text-input :disabled="!invoiceCanBeUpdated" label="Customer Name" v-model="form.customer_name" :errors="combinedErrors.customer_name" />
                <text-input :disabled="!invoiceCanBeUpdated" label="Customer Address Recipient" v-model="form.customer_address_recipient" :errors="combinedErrors.customer_address_recipient" />
                <text-input :markAsRequired="true" :disabled="!invoiceCanBeUpdated" label="Customer Address" v-model="form.customer_address" :errors="combinedErrors.customer_address" />
                <text-input :disabled="!invoiceCanBeUpdated" label="Service Address Recipient" v-model="form.service_address_recipient" :errors="combinedErrors.service_address_recipient" />
                <text-input :disabled="!invoiceCanBeUpdated" label="Service Address" v-model="form.service_address" :errors="combinedErrors.service_address" />
                <text-input :disabled="!invoiceCanBeUpdated" label="Remit To Address" v-model="form.remit_to_address" :errors="combinedErrors.remit_to_address" />
                <text-input :disabled="!invoiceCanBeUpdated" label="Remit To Address Recipient" v-model="form.remit_to_address_recipient" :errors="combinedErrors.remit_to_address_recipient" />
                <text-input :disabled="!invoiceCanBeUpdated" label="Vendor Name" v-model="form.vendor_name" :errors="combinedErrors.vendor_name" />
                <text-input :disabled="!invoiceCanBeUpdated" label="Vendor Address Recipient" v-model="form.vendor_address_recipient" :errors="combinedErrors.vendor_address_recipient" />
                <text-input :markAsRequired="true" :disabled="!invoiceCanBeUpdated" label="Vendor Address" v-model="form.vendor_address" :errors="combinedErrors.vendor_address" />
            </form>
        </div>

        <div class="col-span-2">
            <pdf-document :url="$route('documents.preview', {path: ocrVendorInvoice.gatheredVendorInvoiceFile.document_path})" style="height: 80vh" />
        </div>
    </div>

    <div v-else class="grid grid-cols-3 gap-x-4 gap-y-6">
        <div>
            <label class="form-label">Status</label>

            <span class="mr-4" :class="{'text-green-600': ocrVendorInvoice.status === 'Sent To Audit'}">
                {{  ocrVendorInvoice.status }}
            </span>
        </div>

        <div>
            <label class="form-label">Vendor Account</label>
            <a v-if="form.vendorAccount" class="m-1 link text-sm" target="_blank" :href="$route('vendor-accounts.show', form.vendorAccount.id)">{{ form.vendorAccount.vendor?.name ?? '' }} | {{ form.vendorAccount.account_number }}</a>
            <span v-else>No vendor account is associated</span>
        </div>

        <search-input :markAsRequired="true" @selected="selectedAccountNumber" :disabled="!invoiceCanBeUpdated" label="Account Number" v-model="form.account_number" :default-value="{account_number: form.account_number}" :errors="combinedErrors.account_number" route="json.vendor-accounts.index" value-property="account_number" display-property="account_number">
            <template #searchResult="obj">
                <span class="block mb-1">Account Number: {{ obj.result.account_number }}</span>
                <span class="block text-sm font-bold" v-if="obj.result.status !== 'Active'">{{ obj.result.status }}</span>
                <span class="text-sm">{{ obj.result.vendor?.name  ?? '' }}</span>
            </template>
        </search-input>

        <text-input :markAsRequired="true" :disabled="!invoiceCanBeUpdated" label="Invoice Number" v-model="form.invoice_number" :errors="combinedErrors.invoice_number" />
        <date-input :markAsRequired="true" :disabled="!invoiceCanBeUpdated" label="Invoice Date" v-model="form.invoice_date" :errors="combinedErrors.invoice_date" />
        <date-input :disabled="!invoiceCanBeUpdated" label="Due Date" v-model="form.due_date" :errors="combinedErrors.due_date" />
        <money-input :markAsRequired="true" :defaultCurrency="$page.props.currency" :disabled="!invoiceCanBeUpdated" label="Invoice Total" v-model="form.invoice_total" :errors="combinedErrors.invoice_total" />
        <money-input :markAsRequired="true" :defaultCurrency="$page.props.currency" :disabled="!invoiceCanBeUpdated" label="Amount Due" v-model="form.amount_due" :errors="combinedErrors.amount_due" />
        <date-input :disabled="!invoiceCanBeUpdated" label="Service Start Date" v-model="form.service_start_date" :errors="combinedErrors.service_start_date" />
        <date-input :disabled="!invoiceCanBeUpdated" label="Service End Date" v-model="form.service_end_date" :errors="combinedErrors.service_end_date" />
        <money-input :disabled="!invoiceCanBeUpdated" :defaultCurrency="$page.props.currency" label="Previous Unpaid Balance" v-model="form.previous_unpaid_balance" :errors="combinedErrors.previous_unpaid_balance" />
        <money-input :disabled="!invoiceCanBeUpdated" :defaultCurrency="$page.props.currency" label="Subtotal" v-model="form.subtotal" :errors="combinedErrors.subtotal" />
        <money-input :disabled="!invoiceCanBeUpdated" :defaultCurrency="$page.props.currency" label="Total Tax" v-model="form.total_tax" :errors="combinedErrors.total_tax" />
        <text-input :disabled="!invoiceCanBeUpdated" label="Purchase Order" v-model="form.purchase_order" :errors="combinedErrors.purchase_order" />
        <text-input :disabled="!invoiceCanBeUpdated" label="Customer Name" v-model="form.customer_name" :errors="combinedErrors.customer_name" />
        <text-input :disabled="!invoiceCanBeUpdated" label="Customer Address Recipient" v-model="form.customer_address_recipient" :errors="combinedErrors.customer_address_recipient" />
        <text-input :markAsRequired="true" :disabled="!invoiceCanBeUpdated" label="Customer Address" v-model="form.customer_address" :errors="combinedErrors.customer_address" />
        <text-input :disabled="!invoiceCanBeUpdated" label="Service Address Recipient" v-model="form.service_address_recipient" :errors="combinedErrors.service_address_recipient" />
        <text-input :disabled="!invoiceCanBeUpdated" label="Service Address" v-model="form.service_address" :errors="combinedErrors.service_address" />
        <text-input :disabled="!invoiceCanBeUpdated" label="Remit To Address" v-model="form.remit_to_address" :errors="combinedErrors.remit_to_address" />
        <text-input :disabled="!invoiceCanBeUpdated" label="Remit To Address Recipient" v-model="form.remit_to_address_recipient" :errors="combinedErrors.remit_to_address_recipient" />
        <text-input :disabled="!invoiceCanBeUpdated" label="Vendor Name" v-model="form.vendor_name" :errors="combinedErrors.vendor_name" />
        <text-input :disabled="!invoiceCanBeUpdated" label="Vendor Address Recipient" v-model="form.vendor_address_recipient" :errors="combinedErrors.vendor_address_recipient" />
        <text-input :markAsRequired="true" :disabled="!invoiceCanBeUpdated" label="Vendor Address" v-model="form.vendor_address" :errors="combinedErrors.vendor_address" />
    </div>

    <div class="col-span-3 mt-8">
        <div class="grid grid-cols-2">
            <div>
                <div class="flex">
                    <label class="form-label text-xl">
                        Line Items
                    </label>

                    <div class="form-label text-xl">
                        &nbsp; - Count: {{ form.ocrLineItems.length }} - Total: {{ $filters.format_money(lineItemsTotal) }}
                    </div>
                </div>

                <div v-if="ocrLineItemTotalDifference.amount !== 0" class="text-red-500">
                    Difference between Line Item Total and Invoice Total: {{ $filters.format_money(ocrLineItemTotalDifference) }}
                </div>
            </div>

            <div class="flex flex-row-reverse flex-wrap space-x-4 space-x-reverse items-center">
                <div>
                    <button v-if="invoiceCanBeUpdated" @click="showAddOcrLineItemModal" class="btn btn-orange">New Line Item</button>
                </div>
            </div>
        </div>

        <div v-if="errors.ocrLineItems" class="form-error">{{ errors.ocrLineItems }}</div>
        <div v-if="combinedErrors.line_items" class="form-error">{{ combinedErrors.line_items }}</div>

        <div class="overflow-x-auto">
            <table class="table">
                <thead>
                <tr>
                    <th>Subaccount #</th>
                    <th>Service Address</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Service Description</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Amount</th>
                    <th>Reference #</th>
                    <th>Type</th>
                    <th>Sub-type</th>
                    <th></th>
                </tr>
                </thead>
                <tbody v-for="lineItem in sortedLineItems" :key="lineItem.id">
                <tr>
                    <td>{{ lineItem.subaccount_number }}</td>
                    <td :title="lineItem.service_address">{{ $filters.limit_string(lineItem.service_address ?? '', 20) }}</td>
                    <td>
                            <span v-if="!lineItem.start_date" class="text-red-500 text-4xl">
                                -
                            </span>

                        {{ lineItem.start_date }}
                    </td>
                    <td>
                        {{ lineItem.end_date }}
                    </td>
                    <td :title="lineItem.service_description">{{ $filters.limit_string(lineItem.service_description ?? '', 20) }}</td>
                    <td :title="lineItem.description">
                            <span v-if="!lineItem.description" class="text-red-500 text-4xl">
                                -
                            </span>

                        {{ $filters.limit_string(lineItem.description ?? '', 20) }}
                    </td>
                    <td>
                        {{ lineItem.quantity }}
                    </td>
                    <td>
                        <template v-if="lineItem.unit_price">{{ $filters.format_money(lineItem.unit_price) }}</template>
                    </td>
                    <td>
                            <span v-if="!lineItem.amount" class="text-red-500 text-4xl">
                                -
                            </span>

                        <template v-if="lineItem.amount">{{ $filters.format_money(lineItem.amount) }}</template>
                    </td>
                    <td>{{ lineItem.reference_number }}</td>
                    <td>{{ lineItem.type }}</td>
                    <td v-if="['Allocatable Fee', 'Allocatable Service Fee', 'Extraneous Charge'].includes(lineItem.type)">{{ lineItem.vendorFee?.label }}</td>
                    <td v-else-if="['Sales Tax'].includes(lineItem.type)">{{ lineItem.vendorSalesTaxType.display_name }}</td>
                    <td v-else></td>
                    <td>
                        <div v-if="invoiceCanBeUpdated" class="w-12">
                            <a href="" class="link" @click.prevent="showEditOcrLineItemModal(lineItem)">
                                Edit
                            </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

            <div v-if="router.page.props.permissions.accessVendorCreditTracking" class="mt-8">
                <div class="flex">
                    <label class="text-xl">
                        Available Vendor Credits
                    </label>
                </div>

                <div class="overflow-x-auto">
                    <table v-if="availableVendorCredits.length" class="table">
                        <thead>
                        <tr>
                            <th>Credit Number</th>
                            <th class="text-right">Expected</th>
                            <th class="text-right">Granted</th>
                            <th class="text-right">Applied</th>
                            <th class="text-center">Source</th>
                        </tr>
                        </thead>

                        <tbody v-for="credit in props.availableVendorCredits" :key="credit.id">
                        <tr>
                            <td>{{ credit.display_id }}</td>
                            <td class="text-right">{{ $filters.format_money(credit.expected_amount) }}</td>
                            <td class="text-right">{{ credit.granted_amount ? $filters.format_money(credit.granted_amount) : '' }}</td>
                            <td class="text-right">{{ credit.applied_amount ? $filters.format_money(credit.applied_amount) : '' }}</td>
                            <td class="text-center">{{ credit.source }}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div v-else class="empty-state">
                        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Available Credits</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Add/Edit Line Item Modal -->
    <modal class="max-w-2xl" ref="ocrLineItemModal" tabindex="-1" role="dialog" @closed="emptyModal">
        <template #modal-header="{close}">
            <div class="p-4 border-b border-gray-400 flex justify-between">
                <div class="my-1 text-2xl">
                    <span class="fal" :class="modalAction === 'Edit' ? 'fa-edit' : 'fa-plus'"></span>
                    {{ modalAction }} Line Item
                </div>

                <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                    <icon name="times" class="fill-current inline w-6 h-6" />
                </button>
            </div>
        </template>

        <template #modal-body>
            <div class="px-6 py-4">
                <div class="grid grid-cols-2 gap-x-6 gap-y-4 overflow-x-auto">
                    <text-input :disabled="!invoiceCanBeUpdated" v-model="ocrLineItemForm.subaccount_number" label="Subaccount #" :errors="errors.subaccount_number" />
                    <textarea-input :disabled="!invoiceCanBeUpdated" v-model="ocrLineItemForm.service_address" label="Service Address" :errors="errors.service_address" />
                    <date-input :disabled="!invoiceCanBeUpdated" v-model="ocrLineItemForm.start_date" label="Start Date" :errors="errors.start_date" />
                    <date-input :disabled="!invoiceCanBeUpdated" v-model="ocrLineItemForm.end_date" label="End Date" :errors="errors.end_date" />
                    <textarea-input :disabled="!invoiceCanBeUpdated" v-model="ocrLineItemForm.service_description" label="Service Description" :errors="errors.service_description" />
                    <textarea-input :disabled="!invoiceCanBeUpdated" v-model="ocrLineItemForm.description" label="Description" :errors="errors.description" />
                    <text-input :disabled="!invoiceCanBeUpdated" type="number" v-model="ocrLineItemForm.quantity" label="Quantity" :errors="errors.quantity" />
                    <money-input :defaultCurrency="$page.props.currency" :disabled="!invoiceCanBeUpdated" v-model="ocrLineItemForm.unit_price" label="Unit Price" :errors="errors.unit_price" />
                    <money-input :defaultCurrency="$page.props.currency" :disabled="!invoiceCanBeUpdated" v-model="ocrLineItemForm.amount" label="Amount" :errors="errors.amount" />
                    <text-input :disabled="!invoiceCanBeUpdated" v-model="ocrLineItemForm.reference_number" label="Reference #" :errors="errors.reference_number" />
                    <select-input :disabled="!invoiceCanBeUpdated" v-model="ocrLineItemForm.type" :errors="errors.type" label="Type" @change="ocrLineItemTypeChanged">
                        <option :value="null">-</option>
                        <option v-for="lineItemType in lineItemTypes" :key="lineItemType" :value="lineItemType">{{ lineItemType }}</option>
                    </select-input>
                    <select-input v-if="ocrLineItemForm.type === 'Sales Tax'" :disabled="!invoiceCanBeUpdated" v-model="ocrLineItemForm.vendor_sales_tax_type_id" :errors="errors.vendor_sales_tax_type_id" label="Sales Tax Type">
                        <option :value="null">-</option>
                        <option v-for="vendorSalesTaxType in vendorSalesTaxTypes" :key="vendorSalesTaxType.id" :value="vendorSalesTaxType.id">{{ vendorSalesTaxType.display_name }}</option>
                    </select-input>
                    <select-input v-if="['Allocatable Fee', 'Extraneous Charge', 'Allocatable Service Fee'].includes(ocrLineItemForm.type)" :disabled="!invoiceCanBeUpdated" v-model="ocrLineItemForm.vendor_fee_id" :errors="errors.vendor_fee_id" label="Vendor Fee">
                        <option :value="null">-</option>
                        <option v-for="vendorFee in vendorFees" :key="vendorFee.id" :value="vendorFee.id">{{ vendorFee.label }}</option>
                    </select-input>
                </div>
            </div>
        </template>

        <template #modal-footer="{close}">
            <div class="p-4 flex">
                <button type="button" class="btn btn-gray mr-2" @click="closeModal">Close</button>
                <loading-button :disabled="state !== 'passive' || !invoiceCanBeUpdated" :loading="state !== 'passive'" class="btn btn-orange" @click="modalCallback">Save</loading-button>
            </div>
        </template>
    </modal>
</template>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import Modal from '@/Shared/Modal.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import HorizontalSubNav from '@/Shared/Audit/HorizontalSubNav.vue';
    import PdfDocument from "@/Shared/PDF/PdfDocument.vue";
    import { sortBy } from 'lodash-es';
    import { reactive, ref, inject, watch, computed, onUnmounted, onMounted } from 'vue';
    import { Head, router, usePage } from '@inertiajs/vue3';

    /**
     * Props
     */
    const props = defineProps({
        ocrVendorInvoice: {
            type: Object,
            required: true
        },

        nextOcrVendorInvoiceLink: {
            type: String,
            required: false,
        },

        invoiceIsWritable: {
            type: Boolean,
            required: true
        },

        auditingValidationErrors: {
          type: Object,
          required: true,
        },

        availableVendorCredits: {
            type: Object,
            required: true
        },

        invoiceCanBeUpdated: {
            type: Boolean,
            required: true,
        },

        invoiceCanBeDeleted: {
            type: Boolean,
            required: true,
        },

        lineItemTypes: {
            type: Array,
            required: true
        },

        vendorSalesTaxTypes: {
            type: Array,
            required: true
        },

        isProcessing: {
            type: Boolean,
            required: true
        },

        possibleDuplicateOCRVendorInvoiceId: {
            type: String,
            default: null
        },

        vendorFees: {
            type: Array,
            required: true,
        },

        errors: {
            type: Object,
            default: () => {}
        }
    });

    const emptyLineItem = {
        amount: null,
        subaccount_number: null,
        reference_number: null,
        order_number: null,
        service_address: null,
        quantity: null,
        start_date: '',
        end_date: '',
        description: '',
        service_description: '',
        type: null,
        vendor_sales_tax_type_id: null,
        created_at: null,
        updated_at: null
    };

    const route = inject('route');
    const toast = inject('toast');
	const page = usePage();

    /**
     * State
     */
    const errors = ref({...props.errors});
    const ocrVendorInvoice = reactive({...props.ocrVendorInvoice});
    const form = reactive({...props.ocrVendorInvoice});
    const ocrLineItemForm = reactive({});
    const modalAction = ref('');
    const state = ref(props.isProcessing ? 'processing' : 'passive');
    const otherUsers =  ref([]);
    const ocrLineItemModal = ref(null);
    const modalCallback = ref(() => {});
    const mounted = ref(false);

    Echo.join(`ocr_vendor_invoices.${ocrVendorInvoice.id}`)
        // Presence
        .here((users) => {
            otherUsers.value = users;
        })
        .joining((user) => {
            otherUsers.value.push(user);
        })
        .leaving((user) => {
            otherUsers.value = otherUsers.value.filter(otherUser => otherUser.id !== user.id);
        })

        // Processing
        .listen('.App\\Events\\OCRVendorInvoice\\ProcessOCRVendorInvoiceJobHasStarted', (e) => {
            state.value = 'processing';
        })
        .listen('.App\\Events\\OCRVendorInvoice\\ProcessOCRVendorInvoiceJobHasFinished', (e) => {
            router.reload({
                preserveScroll: true,
                onSuccess: () => {
                    state.value = 'passive';
                    toast.success('Invoice has been successfully processed.');
                },
            });
        })
        .listen('.App\\Events\\OCRVendorInvoice\\ProcessOCRVendorInvoiceJobHasFailed', (e) => {
            router.reload({
                preserveScroll: true,
                onSuccess: () => {
                    state.value = 'passive';
                    toast.error('Invoice processing has failed.');
                    errors.value['processing_errors'] = e.error_message;
                }
            })

        })

        // Reprocessing
        .listen('.App\\Events\\OCRVendorInvoice\\ReprocessOCRVendorInvoiceJobHasStarted', (e) => {
            state.value = 'processing';
        })
        .listen('.App\\Events\\OCRVendorInvoice\\ReprocessOCRVendorInvoiceJobHasFinished', (e) => {
            router.reload({
                preserveScroll: true,
                onSuccess: () => {
                    state.value = 'passive';
                    toast.success('Invoice has been successfully reprocessed.');
                }
            })
        })
        .listen('.App\\Events\\OCRVendorInvoice\\ReprocessOCRVendorInvoiceJobHasFailed', (e) => {
            router.reload({
                preserveScroll: true,
                onSuccess: () => {
                    state.value = 'passive';
                    toast.error('Invoice reprocessing has failed.');
                    errors.value['processing_errors'] = e.error_message;
                }
            })
        });

    onMounted(() => {
        mounted.value = true;
    });

    onUnmounted(() => {
        Echo.leave(`ocr_vendor_invoices.${ocrVendorInvoice.id}`);
    });

    /**
     * Methods
     */
    function archiveOCRVendorInvoice() {
        if (state.value !== 'passive' || ocrVendorInvoice.archived_at) {
            return;
        }

        state.value = 'archiving';

        router.post(route('ocr-vendor-invoices.archive', ocrVendorInvoice.id), {}, {
                preserveScroll: true,
                onFinish: () => {
                    state.value = 'passive';
                }
            });
    }

    function unarchiveOCRVendorInvoice() {
        if (state.value !== 'passive' || !ocrVendorInvoice.archived_at) {
            return;
        }

        state.value = 'unarchiving';

        router.post(route('ocr-vendor-invoices.unarchive', ocrVendorInvoice.id), {}, {
            preserveScroll: true,
            onFinish: () => {
                state.value = 'passive';
            }
        });
    }

    function selectedAccountNumber(vendorAccount) {
        form.vendor_account_id = vendorAccount?.id;
        form.vendorAccount = vendorAccount
    }

    function markOCRVendorInvoiceAsDuplicate() {
        if (state.value === 'passive') {
            state.value = 'marking-ocr-vendor-invoice-as-duplicate';

            router.put(route('ocr-vendor-invoices.mark-as-duplicate', ocrVendorInvoice.id), {}, {
                preserveScroll: true,
                onFinish: () => {
                    state.value = 'passive';
                }
            });
        }
    }

    function unmarkOCRVendorInvoiceAsDuplicate() {
        if (state.value === 'passive') {
            state.value = 'unmarking-ocr-vendor-invoice-as-duplicate';

            router.put(route('ocr-vendor-invoices.unmark-as-duplicate', ocrVendorInvoice.id), {}, {
                preserveScroll: true,
                onFinish: () => {
                    state.value = 'passive';
                }
            });
        }
    }

    function updateOCRVendorInvoice() {
        if (state.value === 'passive') {
            state.value = 'saving';

            router.put(route('ocr-vendor-invoices.update', ocrVendorInvoice.id), form, {
                preserveScroll: true,
                onFinish: () => {
                    state.value = 'passive';
                }
            });
        }
    }

    function deleteInvoice() {
        if (state.value === 'passive' && props.invoiceCanBeDeleted) {
            if (window.confirm("Are you sure that you want to delete this invoice? NOTE: This will remove the GATHERED, EXTRACTED and AUDITED vendor invoice!")) {
                state.value = 'deleting';

                router.delete(route('ocr-vendor-invoices.destroy', ocrVendorInvoice.id), {
                    onFinish: () => {state.value = 'passive'}
                })
            }
        }
    }

    function storeOcrLineItem() {
        if (state.value === 'passive') {
            state.value = 'updating';

            router.post(route('ocr-vendor-invoice-line-items.store', ocrVendorInvoice.id), ocrLineItemForm, {
                preserveScroll: true,
                onSuccess: () => {
                    form.ocrLineItems = [...ocrVendorInvoice.ocrLineItems];
                    closeModal();
                },
                onFinish: () => {
                    state.value = 'passive';
                }
            });
        }
    }

    function updateOcrLineItem() {
        if (state.value === 'passive') {
            state.value = 'updating';
            router.put(route('ocr-vendor-invoice-line-items.update', [ocrVendorInvoice.id, ocrLineItemForm.id]), ocrLineItemForm, {
                preserveScroll: true,
                onSuccess: () =>  {
                    form.ocrLineItems = [...ocrVendorInvoice.ocrLineItems];
                    closeModal();
                },
                onFinish: () => {
                    state.value = 'passive';
                }
            });
        }
    }

    function showAddOcrLineItemModal() {
        modalAction.value = 'Add';
        modalCallback.value = storeOcrLineItem;
        Object.assign(ocrLineItemForm, emptyLineItem);

        ocrLineItemModal.value.show();
    }

    function showEditOcrLineItemModal(lineItem) {
        modalAction.value = 'Edit';
        modalCallback.value = updateOcrLineItem;
        Object.assign(ocrLineItemForm, lineItem);

        ocrLineItemModal.value.show();
    }

    function closeModal() {
        ocrLineItemModal.value.close();
        emptyModal();
    }

    function emptyModal() {
        Object.assign(ocrLineItemForm, emptyLineItem);
    }

    function ocrLineItemTypeChanged() {
        let ocrLineItem = props.ocrVendorInvoice.ocrLineItems.find(ocrLineItem => ocrLineItem.id === ocrLineItemForm.id);

        if (ocrLineItemForm.type !== 'Sales Tax') {
            ocrLineItemForm.vendor_sales_tax_type_id = null;
        }

        if (ocrLineItemForm.type === 'Sales Tax') {
            ocrLineItemForm.vendor_sales_tax_type_id = ocrLineItem?.vendor_sales_tax_type_id;
        }

        if (!['Allocated Fee', 'Extraneous Charge', 'Allocatable Service Fee'].includes(ocrLineItemForm.type)) {
            ocrLineItemForm.vendor_fee_id = null;
        }

        if (['Allocated Fee', 'Extraneous Charge', 'Allocatable Service Fee'].includes(ocrLineItemForm.type)) {
            ocrLineItemForm.vendor_fee_id = ocrLineItem?.vendor_fee_id;
        }
    }

    function processOCRVendorInvoice() {
        if (state.value === 'passive') {
            state.value = 'processing';

            router.put(route('ocr-vendor-invoices.process', ocrVendorInvoice.id), form, {
                preserveScroll: true,
                onFinish: () => {

                },
                onError: () => {
                    state.value = 'passive';
                }
            });
        }
    }

    function reprocessOCRVendorInvoice() {
        if (state.value === 'passive') {
            state.value = 'processing';

            router.put(route('ocr-vendor-invoices.reprocess', ocrVendorInvoice.id), form, {
                preserveScroll: true,
                onFinish: () => {

                },
                onError: () => {
                    state.value = 'passive';
                }
            });
        }
    }

    /**
     * Computed
     */
    const combinedErrors = computed(() => {
        return {...errors.value, ...props.auditingValidationErrors};
    });

    const lineItemsTotal = computed(() => {
        if (!form.ocrLineItems.length) {
            return {
                amount: '0',
                currency: page.props.currency,
            };
        }

        let amount = form.ocrLineItems
            .filter(ocrLineItem => ocrLineItem.amount)
            .filter(ocrLineItem => ocrLineItem.type !== 'Extra Data')
            .reduce((total, ocrLineItem) => {
                return total + parseInt(ocrLineItem.amount.amount) ?? 0;
            }, 0);

        return {
            amount: amount,
            currency: page.props.currency,
        };
    });

    const ocrLineItemTotalDifference = computed(() => {
        let difference = lineItemsTotal.value.amount - (form.invoice_total?.amount ?? 0);

        return {
            amount: difference,
            currency: page.props.currency
        }
    });

    const sortedLineItems = computed(() => {
        if (!form.ocrLineItems.length) {
            return [];
        }

        return sortBy(form.ocrLineItems, ['order_number']);
    });

    /**
     * Watchers
     */
    watch(() => props.errors, (newValue, oldValue) => {
        errors.value = {...newValue};
    });

    watch(() => props.ocrVendorInvoice, (newValue, oldValue) => {
        Object.assign(ocrVendorInvoice, {...newValue});
    });
</script>