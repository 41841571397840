<template>
    <Head :title="`Edit Message - Vendor Credit Request ${vendorCreditRequestMessage.vendorCreditRequest.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credit-requests.index')" class="breadcrumb-link">Vendor Credit Requests</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credit-requests.show', props.vendorCreditRequestMessage.vendorCreditRequest.id)" class="breadcrumb-link">
                Vendor Credit Request {{ props.vendorCreditRequestMessage.vendorCreditRequest.display_id }}
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credit-request-messages.show', props.vendorCreditRequestMessage.id)" class="breadcrumb-link">
                Message
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form @submit.prevent="submitForm" id="create-location-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <div class="flex justify-between py-4">
                <legend class="pt-6">
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Vendor Credit Request Message Details</h3>
                    </div>
                </legend>

                <div class="pt-6">
                    <inertia-link href="" class="btn btn-red" @click.prevent="deleteVendorCreditRequestMessage">Delete</inertia-link>
                </div>
            </div>

            <div class="grid grid-cols-6 gap-y-6 gap-x-4">
                <div class="col-span-6 xs:col-span-4 md:col-span-2">
                    <label>Vendor</label>

                    <div>
                        <inertia-link :href="route('vendors.show', vendorCreditRequestMessage.vendorCreditRequest.vendor.id)" class="text-blue-600 hover:text-blue-900">
                            {{ vendorCreditRequestMessage.vendorCreditRequest.vendor.name }}
                        </inertia-link>
                    </div>
                </div>

                <text-input
                    v-if="props.vendorCreditRequestMessage.sent_to"
                    v-model="form.sent_to"
                    label="Sent To"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.sent_to"
                />

                <date-time-local-input
                    v-if="props.vendorCreditRequestMessage.sent_at"
                    v-model="form.sent_at"
                    label="Sent At"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.sent_at"
                />

                <date-time-local-input
                    v-if="props.vendorCreditRequestMessage.received_at"
                    v-model="form.received_at"
                    label="Received At"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.received_at"
                />

                <div v-if="! props.vendorCreditRequestMessage.file_path" class="col-span-6 xs:col-span-4 md:col-span-2">
                    <span class="block text-sm font-medium text-black-700 space-y-1">Upload Image</span>
                    <input class="py-2" accept="image/*" @input="form.file = $event.target.files[0]" type="file" />
                </div>

                <div class="col-span-6">
                    <quill-editor
                        v-model="form.content"
                        label="Content"
                        :errors="errors.content"
                    />
                </div>

                <div v-if="props.vendorCreditRequestMessage.file_path" class="col-span-6 xs:col-span-2, md:col-span-4">
                    <button v-show="viewImage === false" class="btn btn-gray" @click.prevent="viewImage = true">View image</button>

                    <img v-if="viewImage === true" :src="props.vendorCreditRequestMessage.temp_file_path" class="mb-2"/>

                    <button v-show="viewImage === true" class="btn btn-gray" @click.prevent="viewImage = false">Hide Image</button>

                    <button class="btn btn-gray ml-2 mt-2" @click.prevent="deleteFile">Delete Image</button>
                </div>
            </div>

            <div>
                <loading-button :loading="state === 'saving'" class="btn btn-blue">Save</loading-button>
            </div>
        </fieldset>
    </form>
</template>

<script setup>
    import Icon from "@/Shared/Icon.vue";
    import LoadingButton from "@/Shared/LoadingButton.vue";
    import QuillEditor from "@/Shared/QuillEditor.vue";
    import TextInput from "@/Shared/TextInput.vue";
    import DateTimeLocalInput from "@/Shared/DateTimeLocalInput.vue";
    import { Head, useForm, router } from '@inertiajs/vue3';
    import { ref, onMounted, inject } from "vue";

    // Inject
    const route = inject('route')

    // Props
    const props = defineProps({
        vendorCreditRequestMessage: {
            type: Object,
            required: true,
        },

        errors: {
            type: Object,
            default: () => ({}),
        },
    });

    // State
    const mounted = ref(false);
    const state = ref('passive');
    const viewImage = ref(false);
    const form = useForm({
        sent_to: props.vendorCreditRequestMessage.sent_to ? props.vendorCreditRequestMessage.sent_to : '',
        sent_at: props.vendorCreditRequestMessage.sent_at ? props.vendorCreditRequestMessage.sent_at : null,
        received_at: props.vendorCreditRequestMessage.received_at ? props.vendorCreditRequestMessage.received_at : null,
        content: props.vendorCreditRequestMessage.content ? props.vendorCreditRequestMessage.content : '',
        file: null,
    })

    // Mounted
    onMounted(() => {
        mounted.value = true;
    });

    // Functions
    function submitForm() {
        if (state.value === 'passive') {
            state.value = 'saving';

            form.post(route('vendor-credit-request-messages.update', props.vendorCreditRequestMessage.id), {
                onSuccess: () => {
                    state.value = 'passive';
                },
            });
        }
    }

    function deleteFile() {
        if (window.confirm('Are you sure you want to delete this image?'))

        if (state.value === 'passive') {
            state.value = 'saving';

            router.put(route('vendor-credit-request-messages.remove-image', props.vendorCreditRequestMessage.id), {
                onSuccess: () => {
                    state.value = 'passive';
                },
            });
        }
    }

    function deleteVendorCreditRequestMessage() {
        if (confirm('Are you sure you want to delete this message?')) {
            router.delete(route('vendor-credit-request-messages.destroy', props.vendorCreditRequestMessage.id));
        }
    }
</script>