<template>
    <Head :title="`Vendor - ${vendor.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('vendors.index')" class="breadcrumb-link">Vendors</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ vendor.name }}</span>
        </nav>
    </Teleport>

    <!-- Page header -->
    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div class="flex-shrink-0">
                <div class="relative">
                    <span class="inline-flex items-center justify-center h-14 w-14 rounded-full bg-gray-400 mr-2">
                        <span class="text-lg font-medium leading-none text-white uppercase"></span>
                    </span>
                </div>
            </div>
            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ vendor.name }}
                    <span v-if="vendor.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ vendor.external_reference_id ?? 'EXT-123ABC' }}</span>
                    <p v-if="vendor.deleted_at !== null" class=" text-2xl font-bold text-red-700">(Deleted)</p>
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    <span> Vendor</span> since <time :datetime="vendor.created_at">{{ $filters.format_date_time(vendor.created_at) }}</time>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <inertia-link v-if="vendor.deleted_at === null" :href="$route('vendors.edit', [vendor.id])" class="btn btn-gray">Edit</inertia-link>
            <button v-if="!vendor.deleted_at" @click.prevent="deleteVendor" class="btn btn-red">Delete</button>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="vendor-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="vendor-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor details and records.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                            <div v-if="parentVendorCompany" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Parent Company</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ parentVendorCompany.name }}</dd>
                            </div>

                            <div v-if="vendorTemplate" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Vendor Template</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendorTemplate.name }}</dd>
                            </div>

                            <div v-if="vendor.vendor_portal_id">
                                <dt class="text-sm font-medium text-gray-500">Rover</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendor.vendorPortal.vendor_name }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Invoice Type</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendor.invoice_type }}</dd>
                            </div>

                            <div v-if="vendor.has_gps_tracking_on_trucks" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">GPS Trucking on Trucks</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendor.has_gps_tracking_on_trucks }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Default Weight Unit</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendor.default_weight_unit }}</dd>
                            </div>

<!--                            To be used when we implement vendor credit requests...-->
<!--                            <div v-if="autoCreateCreditRequests" class="sm:col-span-1">-->
<!--                                <dt class="text-sm font-medium text-gray-500">Create Requests Per Invoice</dt>-->
<!--                                <dd class="mt-1 text-sm text-gray-900">{{ vendor.create_request_per_invoice }}</dd>-->
<!--                            </div>-->
                        </dl>
                    </div>
                </div>
            </section>

            <section aria-labelledby="vendor-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="vendor-information-title" class="text-lg leading-6 font-medium text-gray-900">Contact Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">General contact information.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div v-if="vendor.physical_address" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Address</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendor.physical_address.full_address }}</dd>
                            </div>

                            <div v-if="vendor.general_email_address" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Email address</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendor.general_email_address }}</dd>
                            </div>

                            <div v-if="vendor.legal_mailing_address" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Legal Mailing Address</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendor.legal_mailing_address }}</dd>
                            </div>

                            <div v-if="vendor.check_remittance_address" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Remittance Address</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendor.check_remittance_address }}</dd>
                            </div>

                            <div v-if="vendor.work_order_request_email_address" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Work Order Email address</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendor.work_order_request_email_address }}</dd>
                            </div>

                            <div v-if="vendor.general_phone_number" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Phone Number</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ vendor.general_phone_number }}</dd>
                            </div>

                            <div v-if="vendor.general_fax_phone_number" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Fax</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_phone(vendor.general_fax_phone_number) }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>

            <section aria-labelledby="vendor-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="vendor-information-title" class="text-lg leading-6 font-medium text-gray-900">Service Type Offerings</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">The services offered by this vendor.</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div class="mt-6 flow-root">
                            <ul role="list" class="-my-5 divide-y divide-gray-200">
                                <li v-for="offering in vendor.offerings" :key="offering.id" class="py-5">
                                    <div class="relative focus-within:ring-2 focus-within:ring-indigo-500">
                                        <h3 class="text-sm font-semibold text-gray-800">
                                            <span class="">
                                                <!-- Extend touch target to entire panel -->
                                                <span class="absolute inset-0" aria-hidden="true"></span>
                                                {{ offering.name }}
                                            </span>
                                        </h3>

                                        <p class="mt-1 text-sm text-gray-600 line-clamp-2">
                                            {{ offering.description }}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="lg:col-start-3 lg:col-span-1 space-y-6">
            <!-- Sticky Notes !-->
            <sticky-note
                :content="vendor.sticky_notes"
                :hideLabel="true"
                :savingRoute="$route('vendors.sticky-notes-changes.store', [vendor.id])"
                @saved="vendor.sticky_notes = $event"
            />
        </div>

        <!-- Vendor Contacts !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-contacts-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="vendor-contacts-title" class="text-lg leading-6 font-medium text-gray-900">Contacts</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Contacts with this Vendor.</p>
                        </div>

                        <div v-if="vendorContacts.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('vendor-contacts.create', {vendor_id: vendor.id})" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="vendorContacts.length > 0" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Name</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Title</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Email</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Phone</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="contact in vendorContacts.slice(0, 12)" :key="contact.id">

                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
                                                    <inertia-link class="link"
                                                                  :href="$route('vendor-contacts.show', [contact.id])">
                                                        {{ contact.name }}
                                                    </inertia-link>
                                                </td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ contact.title }}</td>
                                                <td class="text-gray-500">{{ contact.email }}</td>
                                                <td class="text-gray-500">{{ $filters.format_phone(contact.primary_phone_number) }}</td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('vendor-contacts.show', [contact.id])" class="link mr-3">
                                                        View<span class="sr-only">, {{ contact.id }}</span>
                                                    </inertia-link>

                                                    <inertia-link :href="$route('vendor-contacts.edit', [contact.id])" class="link">
                                                        Edit<span class="sr-only">, {{ contact.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="address-book" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No contacts</h3>
                            <p class="mt-1 text-sm text-gray-500">Create a contact for this vendor.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('vendor-contacts.create', {vendor_id: vendor.id})" class="btn btn-orange">
                                    <!-- Heroicon name: solid/plus -->
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                    </svg>
                                    New Contact
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="vendorContacts.length">
                        <inertia-link :href="$route('vendor-contacts.index', {search: vendor.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="services-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="services-title" class="text-lg leading-6 font-medium text-gray-900">Services</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Services attached to this Vendor.</p>
                        </div>

                        <div v-if="services.length > 0" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('services.create')" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="services.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                        <tr>
	                                        <th scope="col" class="px-0 text-right text-sm font-semibold text-gray-900">
								                    <span v-tippy="'This column displays an icon if the services is monitored by Pioneer.'">
									                    <icon name="info-circle" class="w-4 h-4 fill-current text-gray-400" />
									                    <span class="sr-only"></span>
								                    </span>
	                                        </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Id</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Type</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Status</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Effective Date </th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Termination Date</th>
                                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Pass Through</th>
	                                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Equipment Label</th>
	                                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Description</th>
                                            <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span class="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                        <tr v-for="service in services.slice(0, 12)" :key="service.id">
	                                        <td>
		                                        <pioneer-indicator v-if="service.compactorMonitor" :compactor-monitor="service.compactorMonitor" />
	                                        </td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">
                                                <inertia-link class="link" :href="$route('services.show', [service.id])">{{ service.id }}</inertia-link>
                                            </td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.type }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.status }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ $filters.format_date(service.effective_date) }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{service.termination_date ? $filters.format_date(service.termination_date) : 'Not Set' }}</td>
                                            <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.is_pass_through ? 'Yes' : 'No' }}</td>
	                                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6">{{ service.label }}</td>
                                            <td class="py-2 pl-4 pr-3 text-sm sm:pl-6 text-gray-500">{{ service.description }}</td>
                                            <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <inertia-link :href="$route('services.show', [service.id])" class="link mr-3"> View
	                                                <span class="sr-only">, {{ service.id }}</span>
                                                </inertia-link>

                                                <inertia-link :href="$route('services.edit', [service.id])" class="link"> Edit
	                                                <span class="sr-only">, {{ service.id }}</span>
                                                </inertia-link>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="dumpster" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Services</h3>
                            <p class="mt-1 text-sm text-gray-500">Create a service.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('services.create')" class="btn btn-orange">
                                    <icon name="plus" class="mr-2 h-4 w-4 fill-current"/>
                                    New Service
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="services.length > 12">
                        <inertia-link :href="$route('services.index', {search: vendor.id})"
                                      class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">
                            View All
                        </inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Locations !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="locations-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="locations-title" class="text-lg leading-6 font-medium text-gray-900">Locations</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Locations serviced by this Vendor</p>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="locations.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Name</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Address</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="location in locations.slice(0, 12)" :key="location.id">
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                                    <inertia-link class="link" :href="$route('locations.show', [location.id])">{{ location.name }}</inertia-link>
                                                </td>
                                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">{{ location.address.full_address }}</td>
                                                
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('locations.edit', [location.id])" class="link">
                                                        Edit<span class="sr-only">, {{ location.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="map-marker-alt" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No locations serviced yet</h3>
                            <p class="mt-1 text-sm text-gray-500">This vendor isn't currently servicing any locations.</p>
                        </div>
                    </div>

                    <div v-if="locations.length">
                        <inertia-link :href="$route('locations.index', {search: vendor.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>

        <!-- Vendor Contracts !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-contracts-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="vendor-contracts-title" class="text-lg leading-6 font-medium text-gray-900">Contracts</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Contracts with this Vendor.</p>
                        </div>

                        <div v-if="vendorContracts.length" class="px-4 py-5 sm:px-6">
                            <inertia-link :href="$route('vendor-contracts.create', {vendor_id: vendor.id})" class="btn btn-orange">New</inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="vendorContracts.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Id</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Status</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Term Months</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Effective Date</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Expiration Date</th>
                                                <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span class="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="contract in vendorContracts.slice(0, 12)" :key="contract.id">
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ contract.id }}</td>
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ contract.status }}</td>
                                                <td class="text-gray-500">{{ contract.term_months }}</td>
                                                <td class="text-gray-500">{{ $filters.format_date(contract.effective_date) }}</td>
                                                <td class="text-gray-500">{{ $filters.format_date(contract.expiration_date) }}</td>
                                                <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <inertia-link :href="$route('vendor-contracts.edit', [contract.id])" class="link">
                                                        Edit<span class="sr-only">, {{ contract.id }}</span>
                                                    </inertia-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div v-else class="text-center">
                            <icon name="file-signature" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No contracts</h3>
                            <p class="mt-1 text-sm text-gray-500">Create a contract.</p>
                            <div class="mt-6">
                                <inertia-link :href="$route('vendor-contracts.create', {vendor_id: vendor.id})" class="btn btn-orange">
                                    <!-- Heroicon name: solid/plus -->
                                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                    </svg>
                                    New Contract
                                </inertia-link>
                            </div>
                        </div>
                    </div>

                    <div v-if="vendorContracts.length">
                        <inertia-link :href="$route('vendor-contracts.index', {search: vendor.name})" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">View All</inertia-link>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, inject, reactive } from 'vue';

    // Components
    import { router, Head } from '@inertiajs/vue3';
    import Icon from '@/Shared/Icon.vue';
    import PioneerIndicator from "../../Shared/PioneerIndicator.vue";
    import StickyNote from "@/Shared/StickyNote.vue";

    // Injects
    const route = inject('route');

    // Props
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({
            })
        },

        vendor: {
            type: Object,
            required: true
        },

        vendorContacts: {
            type: Array,
            required: true
        },

        vendorContracts: {
            type: Array,
            required: true
        },

        locations: {
            type: Array,
            required: true
        },

        autoCreateCreditRequests: {
            type: Boolean,
            required: true,
        },

        parentVendorCompany: {
            type: Object,
            required: false
        },

        vendorTemplate: {
            type: Object,
            required: false
        },

        services: {
            type: Object,
            required: false
        },
    })

    // State
    const mounted = ref(false);
    const vendor = reactive({...props.vendor});

    // Mount
    onMounted(() => {
        mounted.value = true;
    });

    // Functions
    function deleteVendor() {
        if (confirm('Are you sure you want to delete this vendor?')) {
            router.delete(route('vendors.destroy', props.vendor.id));
        }
    }
</script>
