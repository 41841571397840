<template>
    <Head title="Create Vendor Credit Request" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credit-requests.index')" class="breadcrumb-link">Vendor Credit Requests</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <form @submit.prevent="submitForm" id="create-vendor-credit-request-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor credit request details.</p>
                </div>
            </legend>

            <div class="grid grid-cols-6 gap-y-6 gap-x-4">

                <search-input
                    v-model="form.vendor_id"
                    display-property="name"
                    value-property="id"
                    label="Vendor"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.vendor_id"
                    route="json.vendors.index"
                    placeholder="Search"
                    :force-valid="true"
                    @updated="vendorChanged"
                    markAsRequired
                />

                <text-input
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.external_reference_id"
                />
            </div>

            <legend v-if="availableVendorCredits.length === 0" class="font-semibold text-xl text-blue-600 py-2">Select a vendor to view available credits</legend>
            <legend v-else class="font-semibold text-xl text-blue-600 py-2">Available Vendor Credits</legend>

            <div v-if="availableVendorCredits.length">
                <div class="col-span-full">
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col" class="relative px-7 sm:w-12 sm:px-6">
                                <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6" :checked="indeterminate || form.vendor_credit_ids.length === availableVendorCredits.length" :indeterminate="indeterminate" @change="form.vendor_credit_ids = $event.target.checked ? availableVendorCredits.map((vendorCredit) => vendorCredit.id) : []" />
                            </th>
                            <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">ID</th>
                            <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Vendor Account #</th>
                            <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">From Vendor Invoice #</th>
                            <th class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Expected</th>
                            <th class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Granted</th>
                            <th class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Applied</th>
                            <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Actions</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="vendorCredit in availableVendorCredits" :key="vendorCredit.id">
                            <td class="relative px-7 sm:w-12 sm:px-6">
                                <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6" :value="vendorCredit.id" v-model="form.vendor_credit_ids" />
                            </td>
                            <td>
                                <span class="inline-block">
                                    <inertia-link :href="route('vendor-credits.show', vendorCredit.id)" class="link" title="View This Vendor Credit">
                                        {{ vendorCredit.display_id }}
                                    </inertia-link>
                                </span>
                            </td>
                            <td>
                                <inertia-link :href="route('vendor-accounts.show', vendorCredit.vendorAccount.id)" class="link" title="View This Vendor Account">
                                    {{ vendorCredit.vendorAccount.account_number_normalized }}
                                </inertia-link>
                            </td>
                            <td>
                                <inertia-link v-if="vendorCredit.originated_from_vendor_invoice_id !== null" :href="route('vendor-invoices.edit', vendorCredit.originatedFromVendorInvoice.id)" class="link" title="View This Vendor Invoice">
                                    {{ vendorCredit.originatedFromVendorInvoice.invoice_number }}
                                </inertia-link>
                            </td>
                            <td class="text-right">{{ $filters.format_money(vendorCredit.expected_amount) }}</td>
                            <td class="text-right">{{ vendorCredit.granted_amount ? $filters.format_money(vendorCredit.granted_amount) : '' }}</td>
                            <td class="text-right">{{ vendorCredit.applied_amount ? $filters.format_money(vendorCredit.applied_amount) : '' }}</td>
                            <td>
                                <inertia-link :href="route('vendor-credits.show', vendorCredit.id)" class="link" title="View This Vendor Credit">
                                    View
                                </inertia-link>

                                <inertia-link v-show="!vendorCredit.deleted_at" :href="route('vendor-credits.edit', vendorCredit.id)" class="link ml-2" title="Edit This Vendor Credit">
                                    Edit
                                </inertia-link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-else-if="form.vendor_id && availableVendorCredits.length === 0" class="empty-state mt-8 md:mt-12 lg:mt-16">
                <icon name="receipt" class="empty-state-icon h-8 w-8 md:h-12 md:w-12 lg:h-16 lg:w-16" />
                <span class="empty-state-message text-xl md:text-xl lg:text-2xl">No Vendor Credits Found</span>
            </div>

        </fieldset>
        <div>
            <loading-button :loading="state === 'saving'" class="btn btn-blue">Save</loading-button>
        </div>
    </form>
</template>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import TextInput from "@/Shared/TextInput.vue";
    import axios from "axios";
    import { Head, router } from '@inertiajs/vue3';
    import { ref, onMounted, inject, computed } from 'vue';
    import { filters as $filters } from "../../Shared/Utils/Filters";

    // Inject
    const route = inject('route')

    // Props
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        }
    })

    // State
    const state = ref('passive')
    const mounted = ref(false)
    const availableVendorCredits = ref([])
    const form = ref({
        vendor_id: null,
        external_reference_id: null,
        vendor_credit_ids: [],
    })

    // Mounted
    onMounted(() => {
        mounted.value = true;
    })

    // Computed
    const indeterminate = computed(() => {
        return form.value.vendor_credit_ids.length > 0 && form.value.vendor_credit_ids.length < availableVendorCredits.value.length
    })

    // Functions
    function submitForm() {
        if (state.value === 'passive') {
            state.value = 'saving';

            router.post(route('vendor-credit-requests.store'), form.value, {
                onFinish: () => state.value = 'passive',
            });
        }
    }

    function vendorChanged(vendor) {
        if (form.value.vendor_id) {
            axios.get(route('json.vendor-credits.index', {
                vendor_id: vendor.id,
                get_all_records: true
            })).then(response => {
                availableVendorCredits.value = response.data;
            })
        }
    }
</script>