<template>
    <Head title="Vendor Credit Requests" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Vendor Credit Requests</span>
        </nav>
    </Teleport>

    <form action="" id="vendor-credit-requests-search-form" class="grid grid-cols-9 gap-x-4 gap-y-6 my-4 items-center">
        <div class="col-span-6 sm:col-span-3">
            <label for="search-vendor-credit-requests" class="form-label">Search</label>

            <div class="input-group">
                <span class="input-group-item">
                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
                </span>

                <input type="search" name="search-vendor-credit-requests" id="search-vendor-credit-requests" v-model="form.search" placeholder="Search" class="input-group-field">
            </div>
        </div>

        <select-input class="col-span-6 md:col-span-1" name="per_page" id="per-page" v-model="form.per_page" placeholder="15" label="Results / Page">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select-input>

        <div class="col-span-6 md:col-span-1 md:pt-6">
            <div class="flex items-center">
                <button type="button" class="btn btn-gray mr-2" @click="$refs.searchFilterSlideOver.show()">
                    <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
                </button>

                <column-selector
                        :headers="headers"
                        :excludedHeaders="excludedHeaders"
                        v-model="form.selected_headers"
                        v-show="props.vendorCreditRequests.data.length"
                />

                <inertia-link :href="route('vendor-credit-requests.index', {remember: 'forget'})" class="btn btn-gray">Clear</inertia-link>
            </div>
        </div>
    </form>

    <div class="grid grid-cols-4 gap-x-4 gap-y-6 my-3 items-center">
        <div v-if="props.vendorCreditRequests.data.length" class="col-span-4 sm:col-span-2 font-medium text-lg text-steel-700">Showing {{ vendorCreditRequests.from }} - {{ vendorCreditRequests.to }} out of {{ vendorCreditRequests.total }} Vendor Credit Requests.</div>
        <div class="col-start-1 col-span-4 sm:col-span-2 sm:col-end-5 sm:justify-self-end">
            <div class="flex items-center">
                <inertia-link :href="$route('vendor-credit-requests.create')" class="btn btn-blue mr-2">
                    New
                </inertia-link>
            </div>
        </div>
    </div>

    <div v-if="props.vendorCreditRequests.data.length">
        <div class="overflow-x-auto">
            <table class="table table-condensed" ref="table">
                <thead>
                    <tr>
                        <th scope="col" class="relative px-7 sm:w-12 sm:px-6">
                            <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6" :checked="indeterminate || selectedVendorCreditRequestIds.length === props.vendorCreditRequests.data.length" :indeterminate="indeterminate" @change="selectedVendorCreditRequestIds = $event.target.checked ? props.vendorCreditRequests.data.map((vendorCreditRequest) => vendorCreditRequest.id) : []" />
                        </th>
                        <sortable-table-header field="vendor_credit_requests.id" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credit-requests.index">ID</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Vendor')" field="vendors.name" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credit-requests.index">Vendor</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Status')" field="vendor_credit_requests.status" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credit-requests.index">Status</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Message Sent At')" field="vendor_credit_requests.message_sent_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credit-requests.index">Message Sent At</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Reply Received At')" field="vendor_credit_requests.reply_received_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credit-requests.index">Reply Received At</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Last Message At')" field="vendor_credit_requests.last_message_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credit-requests.index">Last Message At</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Follow Up At')" field="vendor_credit_requests.follow_up_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credit-requests.index">Follow Up At</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Closed At')" field="vendor_credit_requests.closed_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credit-requests.index">Closed At</sortable-table-header>
                        <sortable-table-header v-show="form.selected_headers.includes('Created At')" field="vendor_credit_requests.created_at" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" :filters="filters" route-name="vendor-credit-requests.index">Created At</sortable-table-header>
                        <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Actions</th>
                    </tr>
                </thead>

                <tbody>
                <tr v-for="vendorCreditRequest in props.vendorCreditRequests.data" :key="vendorCreditRequest.id">
                    <td class="relative px-7 sm:w-12 sm:px-6">
                        <div v-if="selectedVendorCreditRequestIds.includes(vendorCreditRequest.id)" class="absolute inset-y-0 left-0 w-0.5 bg-blue-600"></div>
                        <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6" :value="vendorCreditRequest.id" v-model="selectedVendorCreditRequestIds" />
                    </td>
                    <td>
                        <span class="inline-block">
                            <inertia-link :href="$route('vendor-credit-requests.show', vendorCreditRequest.id)" class="link" title="Edit This Vendor Credit Request">
                                {{ vendorCreditRequest.display_id }}
                            </inertia-link>
                        </span>
                    </td>
                    <td v-show="form.selected_headers.includes('Vendor')">
                        <inertia-link :href="$route('vendors.show', vendorCreditRequest.vendor.id)" class="link" title="View This Vendor">
                            {{ vendorCreditRequest.vendor.name }}
                        </inertia-link>
                    </td>
                    <td v-show="form.selected_headers.includes('Status')">{{ vendorCreditRequest.status }}</td>
                    <td v-show="form.selected_headers.includes('Message Sent At')">{{ vendorCreditRequest.message_sent_at ? $filters.format_date_time(vendorCreditRequest.message_sent_at) : '' }}</td>
                    <td v-show="form.selected_headers.includes('Reply Received At')">{{ vendorCreditRequest.reply_received_at ? $filters.format_date_time(vendorCreditRequest.reply_received_at) : '' }}</td>
                    <td v-show="form.selected_headers.includes('Last Message At')">{{ vendorCreditRequest.last_message_at ? $filters.format_date_time(vendorCreditRequest.last_message_at) : '' }}</td>
                    <td v-show="form.selected_headers.includes('Follow Up At')">{{ vendorCreditRequest.follow_up_at ? $filters.format_date_time(vendorCreditRequest.follow_up_at) : '' }}</td>
                    <td v-show="form.selected_headers.includes('Closed At')">{{ vendorCreditRequest.closed_at ? $filters.format_date_time(vendorCreditRequest.closed_at) : '' }}</td>
                    <td v-show="form.selected_headers.includes('Created At')">{{ $filters.format_date_time(vendorCreditRequest.created_at) }}</td>
                    <td>
                        <span class="inline-block">
                            <inertia-link :href="route('vendor-credit-requests.show', vendorCreditRequest.id)" class="link" title="View This Vendor Credit Request">
                                View
                            </inertia-link>

                            <inertia-link v-show="!vendorCreditRequest.deleted_at" :href="route('vendor-credit-requests.edit', vendorCreditRequest.id)" class="link ml-2" title="Edit This Vendor Credit Request">
                                Edit
                            </inertia-link>
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <pagination :links="props.vendorCreditRequests.links" />
    </div>

    <div v-else class="empty-state mt-16 md:mt-24 lg:mt-32">
        <icon name="receipt" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Vendor Credit Requests Found</span>
    </div>

    <slide-over ref="searchFilterSlideOver">
        <template #header="{close}">
            <div class="bg-steel-800 border-solid border-b-2 border-orange-600 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form action="" id="credit-request-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-4 items-center">
                <div class="col-span-1" v-show="form.selected_headers.includes('Status')">
                    <Combobox as="div" v-model="form.statuses" multiple>
                        <ComboboxLabel for="statuses" class="form-label">Invoice Status</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput
                                name="statuses"
                                id="statuses"
                                class="form-input"
                                @change="statusComboBoxQuery = $event.target.value"
                                :display-value="comboBoxDisplayValue"
                            />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredStatusOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="option in filteredStatusOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-blue-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ option }}
                                    </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-blue-600']">
                                        <!-- Heroicon name: solid/check -->
                                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>

                <div class="col-span-1" v-show="form.selected_headers.includes('Created At')">
                    <label class="form-label">Created At</label>

                    <date-input v-model="form.created_at_begin" :errors="errors.created_at_begin" />

                    <span class="block text-center">thru</span>

                    <date-input v-model="form.created_at_end" :errors="errors.created_at_end" class="col-span-1" />
                </div>

                <div class="col-span-1" v-show="form.selected_headers.includes('Request Sent At')">
                    <label class="form-label">Resolved At</label>

                    <date-input v-model="form.message_sent_at_begin" :errors="errors.message_sent_at_begin" />

                    <span class="block text-center">thru</span>

                    <date-input v-model="form.message_sent_at_end" :errors="errors.message_sent_at_end" class="col-span-1" />
                </div>

                <select-input id="with-trashed" name="with_trashed" class="col-span-6 sm:col-span-1" v-model="form.with_trashed" label="With Trashed">
                    <option value="except">Hide</option>
                    <option value="include">Include</option>
                    <option value="only">Only</option>
                </select-input>
            </form>
        </template>
    </slide-over>
</template>

<script setup>
    import ColumnSelector from "@/Shared/ColumnSelector.vue";
    import Icon from '@/Shared/Icon.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from "@/Shared/SelectInput.vue";
    import DateInput from "@/Shared/DateInput.vue";
    import SlideOver from "@/Shared/SlideOver.vue";
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import { ref, reactive, onMounted, watch, inject, computed } from 'vue';
    import { Head, router } from '@inertiajs/vue3';
    import { pickBy, throttle } from "lodash-es";
    import { Combobox, ComboboxButton, ComboboxInput, ComboboxLabel, ComboboxOption, ComboboxOptions } from "@headlessui/vue";

    // Inject
    const route = inject('route')

    // Props
    const props = defineProps({
        vendorCreditRequests: {
            type: Object,
            required: true,
        },

        statuses: {
            type: Array,
            required: true,
        },

        filters: {
            type: Object,
            required: true,
        },

        errors: {
            type: Object,
            required: true,
        },
    });

    // State
    const state = ref('passive')
    const mounted = ref(false)
    const selectedVendorCreditRequestIds = ref([])
    const headers = ref([])
    const excludedHeaders = ref(['Id', 'Actions'])
    const table = ref(null)
    const statusComboBoxQuery = ref('')
    const form = reactive({
        search: props.filters.search,
        per_page: props.filters.per_page,
        statuses: props.filters.statuses,
        selected_headers: props.filters.selected_headers,
        created_at_begin: props.filters.created_at_begin,
        created_at_end: props.filters.created_at_end,
        message_sent_at_begin: props.filters.message_sent_at_begin,
        message_sent_at_end: props.filters.message_sent_at_end,
        with_trashed: props.filters.with_trashed ?? 'except',
    })

    // Computed
    const indeterminate = computed(() => {
        return selectedVendorCreditRequestIds.value.length > 0 && selectedVendorCreditRequestIds.value.length < props.vendorCreditRequests.length
    })

    const filteredStatusOptions = computed(() => {
        return statusComboBoxQuery.value === ''
            ? props.statuses
            : props.statuses.filter((status) => {
                return status.toLowerCase().includes(statusComboBoxQuery.value.toLowerCase());
            });
    })

    // Mount
    onMounted(() => {
        mounted.value = true

        if (props.vendorCreditRequests.data.length) {
            getTableHeaders();
        }

        if (form.selected_headers.length === 0) {
            form.selected_headers = headers.value;
        }
    })

    // Functions
    function getTableHeaders() {
        const thElements = table.value.querySelectorAll('th'); // Get all the th elements
        headers.value = Array.from(thElements)
            .filter(th => !th.querySelector('input[type="checkbox"]')) // Filter out checkbox headers
            .map(th => th.textContent)
            .sort((a, b) => a.localeCompare(b));
    }

    function comboBoxDisplayValue(option) {
        if (Array.isArray(option)) {
            if (option.length > 1) {
                return `${option.length} filters selected`;
            } else if (option.length === 1) {
                return '1 filter selected';
            } else {
                return 'No filters selected';
            }
        }

        return option ?? 'No filters selected';
    }

    // Watch
    watch(form, throttle(function() {
        let query = pickBy(form);
        query = Object.keys(query).length ? query : {remember: 'forget'};

        router.get(route('vendor-credit-requests.index'), query, {preserveState: true});
    }, 150), {deep: true})
</script>