<template>
    <Head title="Create Vendor Credit" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credits.index')" class="breadcrumb-link">Vendor Credits</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <form @submit.prevent="submitForm" id="create-vendor-credits-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor credit details.</p>
                </div>
            </legend>

            <div class="grid grid-cols-6 gap-y-6 gap-x-4">

                <search-input
                    v-model="form.location_id"
                    display-property="name"
                    value-property="id"
                    label="Location"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.location_id"
                    route="json.locations.index"
                    placeholder="Search"
                    :force-valid="true"
                    @updated="locationChanged"
                />

                <select-input
                    v-if="form.location_id"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    v-model="form.vendor_account_id"
                    label="Vendor Account"
                    :errors="errors.vendor_account_id"
                    markAsRequired
                >
                    <option v-if="!form.location_id" :value="null">No Location Selected</option>
                    <option v-else-if="availableVendorAccounts.length" :value="null">Select Vendor Account</option>
                    <option v-else :value="null">No Vendor Accounts for this Location</option>
                    <option v-for="vendorAccount in availableVendorAccounts" :key="vendorAccount.id" :value="vendorAccount.id">
                        {{ vendorAccount.account_number }}
                    </option>
                </select-input>

                <search-input
                    v-else
                    v-model="form.vendor_account_id"
                    display-property="account_number"
                    value-property="id"
                    label="Vendor Account"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.vendor_account_id"
                    route="json.vendor-accounts.index"
                    placeholder="Search"
                    :force-valid="true"
                    markAsRequired
                />

                <text-input
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.external_reference_id"
                />

                <money-input
                    v-model="form.expected_amount"
                    label="Expected"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :defaultCurrency="$page.props.currency"
                    :errors="errors.expected_amount"
                    markAsRequired
                />

                <div class="col-span-6">
                    <quill-editor
                        v-model="form.notes"
                        label="Notes"
                        :errors="errors.notes"
                    />
                </div>

            </div>
        </fieldset>
        <div>
            <loading-button :loading="state === 'saving'" class="btn btn-blue">Save</loading-button>
        </div>
    </form>
</template>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import QuillEditor from '@/Shared/QuillEditor.vue';
    import MoneyInput from "@/Shared/MoneyInput.vue";
    import SelectInput from "@/Shared/SelectInput.vue";
    import axios from "axios";
    import { Head, router } from '@inertiajs/vue3';
    import { ref, onMounted, inject } from 'vue';

    // Inject
    const route = inject('route')

    // Props
    const props = defineProps({
        errors: {
            type: Object,
            default: () => ({})
        }
    })

    // State
    const state = ref('passive')
    const mounted = ref(false)
    const availableVendorAccounts = ref([])
    const form = ref({
        expected_amount: null,
        external_reference_id: null,
        location_id: null,
        notes: null,
        vendor_account_id: null,
    })

    // Mounted
    onMounted(() => {
        mounted.value = true;
    })

    // Functions
    function submitForm() {
        if (state.value === 'passive') {
            state.value = 'saving';

            router.post(route('vendor-credits.store'), form.value, {
                onFinish: () => state.value = 'passive',
            });
        }
    }

    function locationChanged(location) {
        if (form.value.location_id) {
            axios.get(route('json.vendor-accounts.index', {
                location_id: location.id,
                get_all_records: true
            })).then(response => {
                availableVendorAccounts.value = response.data;
            })
        }
    }
</script>