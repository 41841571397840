<template>
    <Head :title="`Vendor Credit Request #${props.vendorCreditRequest.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credit-requests.index')" class="breadcrumb-link">Vendor Credit Requests</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ props.vendorCreditRequest.display_id }}</span>
        </nav>
    </Teleport>

    <!-- Page Header -->
    <div
        class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ props.vendor.name }}
                    <span v-if="props.vendorCreditRequest.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ props.vendorCreditRequest.external_reference_id }}</span>
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900"> Vendor Credit Request</span> created on <time :datetime="props.vendorCreditRequest.created_at">{{ $filters.format_date(props.vendorCreditRequest.created_at) }}</time>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <div>
                <button v-if="props.vendorCreditRequest.status !== 'Closed'" class="btn btn-gray" @click.prevent="closeRequest">Close</button>

                <button v-else class="btn btn-gray" @click.prevent="reopenRequest">Re-open</button>
            </div>

            <inertia-link v-if="props.vendorCreditRequest.deleted_at === null" :href="route('vendor-credit-requests.edit', [props.vendorCreditRequest.id])" class="btn btn-gray">Edit</inertia-link>

            <button v-else class="btn btn-gray" @click.prevent="restoreVendorCreditRequest">Restore</button>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="vendor-credit-request-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="vendor-credit-request-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor Credit Request details</p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Request #</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ props.vendorCreditRequest.display_id }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Source</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ props.vendorCreditRequest.source }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Status</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ props.vendorCreditRequest.status }}</dd>
                            </div>
                            <div v-if="props.vendorCreditRequest.message_sent_at" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Message Sent At</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date_time(props.vendorCreditRequest.message_sent_at) }}</dd>
                            </div>
                            <div v-if="props.vendorCreditRequest.reply_received_at" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Last Message At</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date_time(props.vendorCreditRequest.reply_received_at) }}</dd>
                            </div>
                            <div v-if="props.vendorCreditRequest.last_message_at" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Last Message At</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date_time(props.vendorCreditRequest.last_message_at) }}</dd>
                            </div>
                            <div v-if="props.vendorCreditRequest.follow_up_at" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Follow Up At</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date_time(props.vendorCreditRequest.follow_up_at) }}</dd>
                            </div>
                            <div v-if="props.vendorCreditRequest.closed_at" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Closed At</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date_time(props.vendorCreditRequest.closed_at) }}</dd>
                            </div>
                            <div v-if="totalExpectedAmount > 0" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Total Expected</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_money({'amount': totalExpectedAmount, 'currency': currency}) }}</dd>
                            </div>
                            <div v-if="totalAppliedAmount > 0" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Total Applied</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_money({'amount': totalAppliedAmount, 'currency': currency}) }}</dd>
                            </div>
                            <div v-if="totalApprovedAmount > 0" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Total Granted</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_money({'amount': totalGrantedAmount, 'currency': currency}) }}</dd>
                            </div>
                            <div v-if="totalReceivedAmount > 0" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Total Received</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_money({'amount': totalReceivedAmount, 'currency': currency}) }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <div class="lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-credits-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6 col-start-1 col-span-2 flex justify-between">
                        <div>
                            <h2 id="vendor-credits-title" class="text-lg leading-6 font-medium text-gray-900">Vendor Credits</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor credits on this request.</p>
                        </div>

                        <div class="py-2 sm:px-6 justify-end">
                            <inertia-link v-if="props.vendorCreditRequest.status === 'Draft'" class="btn btn-blue" :href="route('vendor-credit-requests.available-credits', props.vendorCreditRequest.id)">
                                View Available Credits
                            </inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="props.vendorCreditRequest.vendorCredits.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Id</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Source</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-right text-sm">Expected</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-right text-sm">Granted</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-right text-sm">Applied</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Resolved</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Rejected</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-right text-sm">Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="vendorCredit in props.vendorCreditRequest.vendorCredits" :key="vendorCredit.id">
                                                <td>
                                                    <inertia-link :href="route('vendor-credits.show', [vendorCredit.id])" class="link">
                                                        {{ vendorCredit.display_id }}
                                                    </inertia-link>
                                                </td>
                                                <td>
                                                    <span v-if="vendorCredit.source === 'Vendor Invoice'">
                                                        <inertia-link :href="route('vendor-invoices.edit', [vendorCredit.originated_from_vendor_invoice_id])" class="link">
                                                            Invoice # {{ vendorCredit.originatedFromVendorInvoice.invoice_number }}
                                                        </inertia-link>
                                                    </span>
                                                    <span v-else>User created</span>
                                                </td>
                                                <td class="text-right">{{ $filters.format_money(vendorCredit.expected_amount) }}</td>
                                                <td class="text-right">{{ vendorCredit.granted_amount ? $filters.format_money(vendorCredit.granted_amount) : '' }}</td>
                                                <td class="text-right">{{ vendorCredit.applied_amount ? $filters.format_money(vendorCredit.applied_amount) : ''}}</td>
                                                <td>
                                                    <icon v-if="vendorCredit.resolved_at !== null" clip-rule="evenodd" name="check" class="ml-2 h-5 w-5 text-green-400 fill-current"/>

                                                    <icon v-else name="times" class="ml-2 h-5 w-5 text-red-400 fill-current" />
                                                </td>
                                                <td>
                                                    <icon v-if="vendorCredit.rejected_at !== null" clip-rule="evenodd" name="check" class="ml-2 h-5 w-5 text-green-400 fill-current"/>

                                                    <icon v-else name="times" class="ml-2 h-5 w-5 text-red-400 fill-current" />
                                                </td>
                                                <td class="text-right">
                                                    <button class="link" @click.prevent="removeCredit(vendorCredit)">
                                                        Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="clipboard" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">There are no vendor credits for this request</h3>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="messages-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-4 sm:px-6 col-start-1 col-span-2 flex justify-between">
                        <div>
                            <h2 id="vendor-credits-title" class="text-lg leading-6 font-medium text-gray-900">Messages</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Messages on this request.</p>
                        </div>

                        <div class="py-2 sm:px-6 justify-end">
                            <inertia-link v-if="props.vendorCreditRequest.status !== 'Closed'" class="btn btn-blue" :href="route('vendor-credit-request-messages.create', props.vendorCreditRequest.id)">
                                New Message
                            </inertia-link>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="props.vendorCreditRequest.vendorCreditRequestMessages.length !== 0" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Content</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Image</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Created By</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Sent To</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Sent At</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Received At</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm">Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="message in props.vendorCreditRequest.vendorCreditRequestMessages" :key="message.id">
                                                <td>
                                                    <inertia-link :href="route('vendor-credit-request-messages.show', message.id)" class="link">
                                                        <dd v-if="message.content" v-html="truncate(message.content)" />
                                                        <dd v-else></dd>
                                                    </inertia-link>
                                                </td>
                                                <td>
                                                    <a v-if="message.file_path" :href="route('documents.preview', {path: message.file_path})" class="link">
                                                        <span>View image</span>
                                                    </a>
                                                    <span v-else></span>
                                                </td>
                                                <td>{{ message.employee.name }}</td>
                                                <td>{{ message.sent_to ? truncate(message.sent_to, {length: 10}) : '' }}</td>
                                                <td>{{ message.sent_at ? $filters.format_date_time(message.sent_at) : ''}}</td>
                                                <td>{{ message.received_at ? $filters.format_date_time(message.received_at) : ''}}</td>
                                                <td>
                                                    <div class="flex">
                                                        <inertia-link :href="route('vendor-credit-request-messages.show', message.id)" class="link">
                                                            View
                                                        </inertia-link>

                                                        <inertia-link v-if="props.vendorCreditRequest.status !== 'Closed'" :href="route('vendor-credit-request-messages.edit', message.id)" class="link ml-2">
                                                            Edit
                                                        </inertia-link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="comment-lines" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">There are no messages for this request</h3>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import { Head, router } from '@inertiajs/vue3';
    import { ref, onMounted, inject } from 'vue';
    import { filters as $filters } from "@/Shared/Utils/Filters";
    import { truncate } from "lodash-es";

    // Inject
    const route = inject('route')

    // Props
    const props = defineProps({
        vendorCreditRequest: {
            type: Object,
            required: true,
        },

        vendor: {
            type: Object,
            required: true,
        },

        errors: {
            type: Object,
            default: () => ({}),
        },
    });

    // State
    const mounted = ref(false)
    const totalExpectedAmount = ref(props.vendorCreditRequest.vendorCredits.reduce((total, credit) => total + parseInt(credit.expected_amount?.amount || 0), 0))
    const totalGrantedAmount = ref(props.vendorCreditRequest.vendorCredits.reduce((total, credit) => total + parseInt(credit.granted_amount?.amount || 0), 0))
    const totalAppliedAmount = ref(props.vendorCreditRequest.vendorCredits.reduce((total, credit) => total + parseInt(credit.applied_amount?.amount || 0), 0))
    const totalReceivedAmount = ref(props.vendorCreditRequest.vendorCredits.reduce((total, credit) => total + parseInt(credit.received_amount?.amount || 0), 0))
    const currency = ref(props.vendor.currency)
    const errors = ref({...props.errors})

    // Mounted
    onMounted(() => {
        mounted.value = true;
    })

    // Functions
    function restoreVendorCreditRequest() {
        if (confirm('Are you sure you want to restore this vendor credit request?')) {
            router.patch(route('vendor-credit-requests.restore', props.vendorCreditRequest.id));
        }
    }

    function closeRequest() {
        if (confirm('Are you sure you want to close this vendor credit request?')) {
            router.patch(route('vendor-credit-requests.close', props.vendorCreditRequest.id));
        }
    }

    function reopenRequest() {
        if (confirm('Are you sure you want to reopen this vendor credit request?')) {
            router.patch(route('vendor-credit-requests.reopen', props.vendorCreditRequest.id));
        }
    }

    function removeCredit(vendorCredit) {
        let creditRemovalRoute = route('vendor-credit-requests.remove-credit', props.vendorCreditRequest.id);

        router.patch(creditRemovalRoute, {
            vendor_credit_request_id: props.vendorCreditRequest.id,
            vendor_credit_id: vendorCredit.id
        }, {
            preserveScroll: true,
            onSuccess: () => {
                totalExpectedAmount.value -= parseInt(vendorCredit.expected_amount?.amount || 0)
                totalGrantedAmount.value -= parseInt(vendorCredit.granted_amount?.amount || 0)
                totalAppliedAmount.value -= parseInt(vendorCredit.applied_amount?.amount || 0)
                totalReceivedAmount.value -= parseInt(vendorCredit.received_amount?.amount || 0)
            }
        });
    }
</script>