<template>
    <Head :title="`Edit Vendor Credit Request ${props.vendorCreditRequest.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credit-requests.index')" class="breadcrumb-link">Vendor Credit Requests</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credit-requests.show', props.vendorCreditRequest.id)" class="breadcrumb-link">{{ props.vendorCreditRequest.display_id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form @submit.prevent="submitForm" id="edit-vendor-credit-request-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <div class="flex justify-between py-4">
                <legend class="pt-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Vendor Credit Request Details</h3>
                </legend>

                <div class="pt-6">
                    <inertia-link v-if="props.vendorCreditRequest.deleted_at === null" href="" class="ml-2 btn btn-red sm:justify-self-end" @click.prevent="deleteVendorCreditRequest">Delete</inertia-link>
                </div>
            </div>

            <div class="grid grid-cols-6 gap-y-6 gap-x-4">
                <div class="col-span-6 xs:col-span-4 md:col-span-2">
                    <label>Vendor</label>
                    <div>
                        <inertia-link :href="route('vendors.show', props.vendorCreditRequest.vendor_id)" class="text-blue-600 hover:text-blue-900">
                            {{ props.vendorCreditRequest.vendor.name }}
                        </inertia-link>
                    </div>
                </div>

                <select-input
                    v-model="form.status"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    label="Status"
                    :errors="errors.status"
                >
                    <option v-for="status in props.statuses" :key="status" :value="status">
                        {{ status }}
                    </option>
                </select-input>

                <date-time-local-input
                    v-model="form.message_sent_at"
                    label="Message Sent At"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.message_sent_at"
                />

                <date-time-local-input
                    v-model="form.reply_received_at"
                    label="Reply Received At"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.reply_received_at"
                />

                <date-time-local-input
                    v-model="form.follow_up_at"
                    label="Follow Up At"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.follow_up_at"
                />

                <text-input
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.external_reference_id"
                />
            </div>
        </fieldset>

        <div>
            <loading-button :loading="state === 'saving'" class="btn btn-blue">Save</loading-button>
        </div>
    </form>
</template>

<script setup>
    import Icon from "@/Shared/Icon.vue";
    import LoadingButton from "@/Shared/LoadingButton.vue";
    import TextInput from "@/Shared/TextInput.vue";
    import DateTimeLocalInput from "@/Shared/DateTimeLocalInput.vue";
    import SelectInput from "@/Shared/SelectInput.vue";
    import { Head, router } from '@inertiajs/vue3';
    import { ref, onMounted, inject } from "vue";

    // Inject
    const route = inject('route')

    // Props
    const props = defineProps({
        vendorCreditRequest: {
            type: Object,
            required: true,
        },

        statuses: {
            type: Array,
            required: true,
        },

        errors: {
            type: Object,
            default: () => ({}),
        },
    });

    // State
    const mounted = ref(false);
    const state = ref('passive');
    const form = ref({
        status: props.vendorCreditRequest.status,
        message_sent_at: props.vendorCreditRequest.message_sent_at ? props.vendorCreditRequest.message_sent_at : null,
        reply_received_at: props.vendorCreditRequest.reply_received_at ? props.vendorCreditRequest.reply_received_at : null,
        follow_up_at: props.vendorCreditRequest.follow_up_at ? props.vendorCreditRequest.follow_up_at : null,
        external_reference_id: props.vendorCreditRequest.external_reference_id ? props.vendorCreditRequest.external_reference_id : null,
    })

    // Mounted
    onMounted(() => {
        mounted.value = true;
    });

    // Functions
    function submitForm() {
        if (state.value === 'passive') {
            state.value = 'saving';

            router.put(route('vendor-credit-requests.update', props.vendorCreditRequest.id), form.value, {
                onSuccess: () => state.value = 'passive',
                onError: () => state.value = 'passive',
            });
        }
    }

    function deleteVendorCreditRequest() {
        if (confirm('Are you sure you want to delete this vendor credit request?')) {
            router.delete(route('vendor-credit-requests.destroy', props.vendorCreditRequest.id));
        }
    }
</script>