<template>
    <Head :title="`Edit Vendor Credit ${vendorCredit.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credits.index')" class="breadcrumb-link">Vendor Credits</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credits.show', props.vendorCredit.id)" class="breadcrumb-link">{{ props.vendorCredit.display_id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form @submit.prevent="submitForm" id="create-location-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <div class="flex justify-between py-4">
                <legend class="pt-6">
                    <div class="mb-3">
                        <h3 class="text-xl leading-6 font-medium font-semibold text-gray-900">Vendor Credit Details</h3>
                    </div>

                    <div class="col-span-6 xs:col-span-4 md:col-span-2">
                        <label>Vendor Account # </label>
                        <inertia-link :href="route('vendor-accounts.show', props.vendorCredit.vendor_account_id)" class="text-blue-600 hover:text-blue-900">
                            {{ vendorCredit.vendorAccount.account_number }}
                        </inertia-link>
                    </div>

                    <div v-if="props.vendorCredit.originatedFromVendorInvoice">
                        <label>Vendor Invoice # </label>
                        <inertia-link :href="route('vendor-invoices.edit', props.vendorCredit.originated_from_vendor_invoice_id)" class="text-blue-600 hover:text-blue-900">
                            {{ vendorCredit.originatedFromVendorInvoice.invoice_number }}
                        </inertia-link>
                    </div>
                </legend>
            </div>

            <div class="grid grid-cols-6 gap-y-6 gap-x-4">
                <money-input
                    v-if="! props.vendorCredit.rejected_at"
                    v-model="form.expected_amount"
                    label="Expected"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :defaultCurrency="$page.props.currency"
                    :errors="errors.expected_amount"
                />

                <money-input
                    v-if="! props.vendorCredit.rejected_at"
                    v-model="form.granted_amount"
                    label="Granted"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :defaultCurrency="$page.props.currency"
                    :errors="errors.granted_amount"
                />

                <money-input
                    v-if="! props.vendorCredit.rejected_at"
                    v-model="form.applied_amount"
                    label="Applied"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :defaultCurrency="$page.props.currency"
                    :errors="errors.applied_amount"
                />

                <money-input
                    v-if="! props.vendorCredit.rejected_at"
                    v-model="form.received_amount"
                    label="Received"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :defaultCurrency="$page.props.currency"
                    :errors="errors.received_amount"
                />

                <select-input
                    v-if="! props.vendorCredit.rejected_at"
                    v-model="form.originated_from_vendor_invoice_id"
                    label="Originated From Vendor Invoice #"
                    :errors="errors.originated_from_vendor_invoice_id"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                >
                    <option :value="null">Unknown</option>
                    <option v-for="invoice in props.vendorInvoices" :value="invoice.id" :key="invoice.id">{{ invoice.invoice_number }}</option>
                </select-input>

                <text-input
                    v-if="! props.vendorCredit.rejected_at"
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.external_reference_id"
                />

                <div class="col-span-6">
                    <quill-editor
                        v-model="form.notes"
                        label="Notes"
                        :errors="errors.notes"
                    />
                </div>
            </div>
        </fieldset>

        <div>
            <loading-button :loading="state === 'saving'" class="btn btn-blue">Save</loading-button>
        </div>
    </form>
</template>

<script setup>
    import Icon from "@/Shared/Icon.vue";
    import LoadingButton from "@/Shared/LoadingButton.vue";
    import MoneyInput from "@/Shared/MoneyInput.vue";
    import QuillEditor from "@/Shared/QuillEditor.vue";
    import TextInput from "@/Shared/TextInput.vue";
    import { Head, router } from '@inertiajs/vue3';
    import { ref, onMounted, inject } from "vue";
    import SelectInput from "@/Shared/SelectInput.vue";

    // Inject
    const route = inject('route')

    // Props
    const props = defineProps({
        vendorCredit: {
            type: Object,
            required: true,
        },

        vendorInvoices: {
            type: Object,
            required: true,
        },

        errors: {
            type: Object,
            default: () => ({}),
        },
    });

    // State
    const mounted = ref(false);
    const state = ref('passive');
    const form = ref({
        expected_amount: props.vendorCredit.expected_amount,
        granted_amount: props.vendorCredit.granted_amount ? props.vendorCredit.granted_amount : null,
        applied_amount: props.vendorCredit.applied_amount ? props.vendorCredit.applied_amount : null,
        received_amount: props.vendorCredit.received_amount ? props.vendorCredit.received_amount : null,
        originated_from_vendor_invoice_id: props.vendorCredit.originated_from_vendor_invoice_id ? props.vendorCredit.originated_from_vendor_invoice_id : null,
        external_reference_id: props.vendorCredit.external_reference_id ? props.vendorCredit.external_reference_id : null,
        notes: props.vendorCredit.notes ? props.vendorCredit.notes : null,
    })

    // Mounted
    onMounted(() => {
        mounted.value = true;
    });

    // Functions
    function submitForm() {
        if (state.value === 'passive') {
            state.value = 'saving';

            router.put(route('vendor-credits.update', props.vendorCredit.id), form.value, {
                onFinish: () => state.value = 'passive',
            });
        }
    }
</script>