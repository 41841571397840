<template>
    <Head :title="`Available Credits - Vendor Credit Request #${vendorCreditRequest.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credit-requests.index')" class="breadcrumb-link">Vendor Credit Requests</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credit-requests.show', props.vendorCreditRequest.id)">{{ props.vendorCreditRequest.display_id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Available Credits</span>
        </nav>
    </Teleport>

    <!-- Page Header -->
    <div
        class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ props.vendorCreditRequest.vendor.name }}
                    <span v-if="props.vendorCreditRequest.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ props.vendorCreditRequest.external_reference_id }}</span>
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900"> Vendor Credit Request</span> created on <time :datetime="props.vendorCreditRequest.created_at">{{ $filters.format_date(props.vendorCreditRequest.created_at) }}</time>
                </p>
            </div>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="vendor-credit-request-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="vendor-credit-request-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor Credit Request details</p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Request #</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ props.vendorCreditRequest.display_id }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Source</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ props.vendorCreditRequest.source }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <div class="lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="vendor-credits-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6 col-start-1 col-span-2 flex justify-between">
                        <div>
                            <h2 id="vendor-credits-title" class="text-lg leading-6 font-medium text-gray-900">Vendor Credits</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor credits available for this request.</p>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div class="col-span-full" v-if="props.availableCredits.length > 0">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table mt-2">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="relative px-7 sm:w-12 sm:px-6">
                                                    <input
                                                        type="checkbox"
                                                        class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6"
                                                        :checked="indeterminate || selectedVendorCreditIds.length === props.availableCredits.length"
                                                        :indeterminate="indeterminate"
                                                        @change="selectedVendorCreditIds = $event.target.checked ? props.availableCredits.map((vendorCredit) => vendorCredit.id) : []"
                                                    />
                                                </th>
                                                <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Vendor Account #</th>
                                                <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">From Vendor Invoice #</th>
                                                <th class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Expected</th>
                                                <th class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Granted</th>
                                                <th class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Applied</th>
                                                <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="vendorCredit in props.availableCredits" :key="vendorCredit.id">
                                                <td class="relative px-7 sm:w-12 sm:px-6">
                                                    <input
                                                        type="checkbox"
                                                        class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6"
                                                        :value="vendorCredit.id"
                                                        v-model="selectedVendorCreditIds"
                                                    />
                                                </td>
                                                <td>
                                                    <inertia-link :href="route('vendor-accounts.show', vendorCredit.vendorAccount.id)" class="link" title="View This Vendor Account">
                                                        {{ vendorCredit.vendorAccount.account_number_normalized }}
                                                    </inertia-link>
                                                </td>
                                                <td>
                                                    <inertia-link v-if="vendorCredit.originated_from_vendor_invoice_id !== null" :href="route('vendor-invoices.edit', vendorCredit.originatedFromVendorInvoice.id)" class="link" title="View This Vendor Invoice">
                                                        {{ vendorCredit.originatedFromVendorInvoice.invoice_number }}
                                                    </inertia-link>
                                                </td>
                                                <td class="text-right">{{ $filters.format_money(vendorCredit.expected_amount) }}</td>
                                                <td class="text-right">{{ vendorCredit.granted_amount ? $filters.format_money(vendorCredit.granted_amount) : '' }}</td>
                                                <td class="text-right">{{ vendorCredit.applied_amount ? $filters.format_money(vendorCredit.applied_amount) : '' }}</td>
                                                <td>
                                                    <div class="flex">
                                                        <inertia-link :href="route('vendor-credits.show', vendorCredit.id)" class="link" title="View This Vendor Credit">
                                                            View
                                                        </inertia-link>

                                                        <inertia-link v-show="!vendorCredit.deleted_at" :href="route('vendor-credits.edit', vendorCredit.id)" class="link ml-2" title="Edit This Vendor Credit">
                                                            Edit
                                                        </inertia-link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="flex col-span-full justify-center py-4">
                            <span class="font-semibold">There are no credits available for this request</span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

    <div class="mt-6 sm:px-6 col-start-1">
        <button class="btn btn-blue" @click.prevent="attachSelectedCredits" :disabled="isEmpty(selectedVendorCreditIds)" v-if="availableCredits.length > 0">
            Attach Selected Credits
        </button>
    </div>
</template>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import { ref, inject, onMounted, computed } from "vue";
    import { Head, router } from "@inertiajs/vue3";
    import { isEmpty } from "lodash-es";

    // Inject
    const route = inject('route');

    // State
    const mounted = ref(false)
    const selectedVendorCreditIds = ref([])

    // Mounted
    onMounted(() => {
        mounted.value = true
    })

    // Props
    const props = defineProps({
        vendorCreditRequest: {
            type: Object,
            required: true,
        },

        availableCredits: {
            type: Array,
            required: true,
        },

        errors: {
            type: Object,
            required: true,
        },
    });

    // Computed
    const indeterminate = computed(() => {
        return selectedVendorCreditIds.value.length > 0 && selectedVendorCreditIds.value.length < props.availableCredits.length
    })

    // Methods
    function attachSelectedCredits() {
        if (selectedVendorCreditIds.value.length > 0) {
            const data = {
                vendor_credit_request_id: props.vendorCreditRequest.id,
                vendor_credit_ids: selectedVendorCreditIds.value,
            }

            router.patch(route('vendor-credit-requests.attach-credits', props.vendorCreditRequest.id), data, {
                preserveScroll: true,
                onSuccess: () => {
                    selectedVendorCreditIds.value = []
                },
            })
        }
    }
</script>