<template>
    <Head :title="`Create Message - Vendor Credit Request ${props.vendorCreditRequest.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credit-requests.index')" class="breadcrumb-link">Vendor Credit Requests</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credit-requests.show', props.vendorCreditRequest.id)" class="breadcrumb-link">Request {{ props.vendorCreditRequest.display_id }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create Message</span>
        </nav>
    </Teleport>

    <form @submit.prevent="submitForm" id="create-location-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Information</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor credit request message details.</p>
                </div>
            </legend>

            <div class="grid grid-cols-6 gap-y-6 gap-x-4">
                <text-input
                    v-model="form.sent_to"
                    label="Sent To"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.sent_to"
                />

                <date-time-local-input
                    v-if="! form.received_at"
                    v-model="form.sent_at"
                    label="Sent At"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.sent_at"
                />

                <date-time-local-input
                    v-if="! form.sent_at"
                    v-model="form.received_at"
                    label="Received At"
                    class="col-span-6 xs:col-span-4 md:col-span-2"
                    :errors="errors.received_at"
                />

                <div class="col-span-6 xs:col-span-4 md:col-span-2">
                    <span class="block text-sm font-medium text-black-700 space-y-1">Upload Image</span>
                    <input class="py-2" accept="image/*" @input="form.file = $event.target.files[0]" type="file" />
                </div>

                <div class="col-span-6">
                    <quill-editor
                        v-model="form.content"
                        label="Content"
                        :errors="errors.content"
                    />
                </div>
            </div>
        </fieldset>

        <div>
            <loading-button :loading="state === 'saving'" class="btn btn-blue">Save</loading-button>
        </div>
    </form>
</template>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import QuillEditor from '@/Shared/QuillEditor.vue';
    import DateTimeLocalInput from "@/Shared/DateTimeLocalInput.vue";
    import { Head, router } from '@inertiajs/vue3';
    import { ref, onMounted, inject } from 'vue';

    // Inject
    const route = inject('route')

    // Props
    const props = defineProps({
        vendorCreditRequest: {
            type: Object,
            required: true,
        },

        errors: {
            type: Object,
            default: () => ({})
        }
    })

    // State
    const state = ref('passive')
    const mounted = ref(false)
    const form = ref({
        sent_to: null,
        sent_at: null,
        received_at: null,
        content: null,
        vendor_credit_request_id: props.vendorCreditRequest.id,
        file: null,
    })

    // Mounted
    onMounted(() => {
        mounted.value = true;
    })

    // Functions
    function submitForm() {
        if (state.value === 'passive') {
            state.value = 'saving';

            router.post(route('vendor-credit-request-messages.store'), form.value, {
                onFinish: () => state.value = 'passive',
            });
        }
    }
</script>