<template>
    <Head :title="`Vendor Credit - ${props.vendorCredit.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credits.index')" class="breadcrumb-link">Vendor Credits</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ vendorCredit.display_id }}</span>
        </nav>
    </Teleport>

    <!-- Page Header -->
    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ vendorCredit.display_id }}
                    <span v-if="vendorCredit.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ vendorCredit.external_reference_id }}</span>
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900"> Vendor Credit</span> created on <time :datetime="vendorCredit.created_at">{{ $filters.format_date(vendorCredit.created_at) }}</time>
                </p>
            </div>
        </div>

        <div class="mt-6 space-x-3 flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <button v-if="props.vendorCredit.resolved_at === null && props.vendorCredit.rejected_at === null" class="btn btn-gray" @click.prevent="resolveCredit">Resolve</button>
            <button v-if="props.vendorCredit.resolved_at !== null && props.vendorCredit.rejected_at === null" class="btn btn-gray" @click.prevent="unresolveCredit">Un-resolve</button>
            <button v-if="props.vendorCredit.rejected_at === null && props.vendorCredit.resolved_at === null" class="btn btn-gray" @click.prevent="rejectCredit">Reject</button>
            <button v-if="props.vendorCredit.rejected_at !== null && props.vendorCredit.resolved_at === null" class="btn btn-gray" @click.prevent="unrejectCredit">Un-reject</button>

            <inertia-link v-if="vendorCredit.deleted_at === null" :href="route('vendor-credits.edit', [vendorCredit.id])" class="btn btn-gray">Edit</inertia-link>

            <inertia-link v-if="props.vendorCredit.deleted_at === null" href="" class="btn btn-red" @click.prevent="deleteVendorCredit">Delete</inertia-link>

            <inertia-link v-else href="" class="btn btn-gray" @click.prevent="restoreVendorCredit">Restore</inertia-link>
        </div>
    </div>

    <div class="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:mt-8 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor Credit details</p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Vendor</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link class="link" :href="route('vendors.show', [props.vendorCredit.vendorAccount.vendor.id])">
                                        {{ props.vendorCredit.vendorAccount.vendor.name }}
                                    </inertia-link>
                                </dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Vendor Account</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link class="link" :href="route('vendor-accounts.show', [props.vendorCredit.vendor_account_id])">
                                        {{ props.vendorCredit.vendorAccount.account_number }}
                                    </inertia-link>
                                </dd>
                            </div>
                            <div class="sm:col-span-1" v-if="props.vendorCredit.originatedFromLineItemRevision?.lineItem?.service">
                                <dt class="text-sm font-medium text-gray-500">Client Company</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link class="link" :href="route('client-companies.show', [props.vendorCredit.originatedFromLineItemRevision.lineItem.service.location.clientCompany.id])">
                                        {{ props.vendorCredit.originatedFromLineItemRevision.lineItem.service.location.clientCompany.name }}
                                    </inertia-link>
                                </dd>
                            </div>
                            <div class="sm:col-span-1" v-if="props.vendorCredit.originatedFromLineItemRevision?.lineItem?.service">
                                <dt class="text-sm font-medium text-gray-500">Location</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link class="link" :href="route('locations.show', [props.vendorCredit.originatedFromLineItemRevision.lineItem.service.location.id])">
                                        {{ props.vendorCredit.originatedFromLineItemRevision.lineItem.service.location.name }}
                                    </inertia-link>
                                </dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Expected Amount</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_money(props.vendorCredit.expected_amount) }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Granted Amount</dt>
                                <dd v-if="props.vendorCredit.granted_amount && props.vendorCredit.rejected_at === null" class="mt-1 text-sm text-gray-900">{{ $filters.format_money(props.vendorCredit.granted_amount) }}</dd>
                                <dd v-if="props.vendorCredit.granted_amount === null && props.vendorCredit.rejected_at === null" class="mt-1 text-sm text-gray-900">Pending</dd>
                                <dd v-if="props.vendorCredit.rejected_at !== null" class="mt-1 text-sm text-gray-900">Rejected</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Applied Amount</dt>
                                <dd v-if="props.vendorCredit.applied_amount" class="mt-1 text-sm text-gray-900">{{ $filters.format_money(props.vendorCredit.applied_amount) }}</dd>
                                <dd v-else class="mt-1 text-sm text-gray-900">Credit not applied</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Received Amount</dt>
                                <dd v-if="props.vendorCredit.received_amount" class="mt-1 text-sm text-gray-900">{{ $filters.format_money(props.vendorCredit.received_amount) }}</dd>
                                <dd v-else class="mt-1 text-sm text-gray-900">Credit not received</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Source</dt>
                                <dd v-if="props.vendorCredit.source === 'User Requested'" class="mt-1 text-sm text-gray-900">{{ props.vendorCredit.source }}</dd>
                                <dd v-else class="mt-1 text-sm text-gray-900">
                                    Vendor Invoice #:
                                    <inertia-link class="link" :href="route('vendor-invoices.edit', [props.vendorCredit.originated_from_vendor_invoice_id])">
                                        {{ props.vendorCredit.originatedFromVendorInvoice.invoice_number }}
                                    </inertia-link>
                                </dd>
                            </div>
                            <div class="sm:col-span-1" v-if="props.vendorCredit.resolved_from_vendor_invoice_id">
                                <dt class="text-sm font-medium text-gray-500">Resolved From Vendor Invoice</dt>
                                <dd class="mt-1 text-sm text-gray-900">
                                    <inertia-link class="link" :href="route('vendor-invoices.edit', [props.vendorCredit.resolved_from_vendor_invoice_id])">
                                        {{ props.vendorCredit.resolvedFromVendorInvoice.invoice_number }}
                                    </inertia-link>
                                </dd>
                            </div>
                            <div class="sm:col-span-1" v-if="props.vendorCredit.resolved_at">
                                <dt class="text-sm font-medium text-gray-500">Resolved At</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date_time(props.vendorCredit.resolved_at) }}</dd>
                            </div>
                            <div class="sm:col-span-1" v-if="props.vendorCredit.rejected_at">
                                <dt class="text-sm font-medium text-gray-500">Rejected At</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date_time(props.vendorCredit.rejected_at) }}</dd>
                            </div>
                            <div class="sm:col-span-1" v-if="props.vendorCredit.deleted_at">
                                <dt class="text-sm font-medium text-gray-500">Deleted At</dt>
                                <dd class="mt-1 text-sm text-red-600">{{ $filters.format_date_time(props.vendorCredit.deleted_at) }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <section aria-labelledby="notes-title" class="lg:col-start-3 lg:col-span-1">
            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 bg-yellow-50 border-gray-100">
                <h2 id="notes-title" class="text-lg font-medium text-gray-900">Notes</h2>

                <dl v-if="props.vendorCredit.notes">
                    <dd class="mt-1 text-gray-900" v-html="props.vendorCredit.notes" />
                </dl>
            </div>
        </section>

        <!-- Vendor Invoice Line Item !-->
        <div v-if="props.vendorCredit.originatedFromLineItemRevision?.lineItem" class="space-y-6 lg:col-start-1 lg:col-span-2">
            <section aria-labelledby="vendor-invoice-line-item-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="vendor-invoice-line-item-title" class="text-lg leading-6 font-medium text-gray-900">Vendor Invoice Line Item</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500">Info from the line item this credit originated from.</p>
                        </div>
                    </div>

                    <div class="border-t border-gray-100">
                        <dl class="divide-y divide-gray-100">
                            <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-900">Type</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ props.vendorCredit.originatedFromLineItemRevision.lineItem.type }}</dd>
                            </div>
                            <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" v-if="props.vendorCredit.originatedFromLineItemRevision?.lineItem?.vendorFee">
                                <dt class="text-sm font-medium text-gray-900">Vendor Fee</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ props.vendorCredit.originatedFromLineItemRevision.lineItem.vendorFee.label }}</dd>
                            </div>
                            <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" v-if="props.vendorCredit.originatedFromLineItemRevision?.lineItem?.service">
                                <dt class="text-sm font-medium text-gray-900">Service</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    <inertia-link class="link" :href="route('services.show', [props.vendorCredit.originatedFromLineItemRevision.lineItem.service.id])">
                                        {{ props.vendorCredit.originatedFromLineItemRevision.lineItem.service.id }}
                                    </inertia-link>
                                </dd>
                            </div>
                            <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-900">Date</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ $filters.format_date(props.vendorCredit.originatedFromLineItemRevision.lineItem.start_date) }}</dd>
                            </div>
                            <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-900">Description</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ props.vendorCredit.originatedFromLineItemRevision.lineItem.description }}</dd>
                            </div>
                            <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-900">Quantity</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ props.vendorCredit.originatedFromLineItemRevision.lineItem.quantity }}</dd>
                            </div>
                            <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-900">Original Amount</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ $filters.format_money(props.vendorCredit.originatedFromLineItemRevision.old_amount) }}</dd>
                            </div>
                            <div class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-900">Revised Amount</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ $filters.format_money(props.vendorCredit.originatedFromLineItemRevision.new_amount) }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import { Head, router } from '@inertiajs/vue3';
    import { ref, onMounted, inject } from 'vue';
    import { filters as $filters } from "@/Shared/Utils/Filters";

    // Inject
    const route = inject('route')

    // Props
    const props = defineProps({
        vendorCredit: {
            type: Object,
            required: true,
        },

        errors: {
            type: Object,
            default: () => ({}),
        },
    });

    // State
    const mounted = ref(false);
    const errors = ref({...props.errors});

    // Mounted
    onMounted(() => {
        mounted.value = true;
    });

    // Functions
    function restoreVendorCredit() {
        if (confirm('Are you sure you want to restore this vendor credit?')) {
            router.patch(route('vendor-credits.restore', props.vendorCredit.id));
        }
    }

    function resolveCredit() {
        if (confirm('Are you sure you want to resolve this vendor credit?')) {
            router.patch(route('vendor-credits.resolve'), {
                vendor_credit_ids: [props.vendorCredit.id],
            });
        }
    }

    function unresolveCredit() {
        if (confirm('Are you sure you want to un-resolve this vendor credit?')) {
            router.patch(route('vendor-credits.unresolve'), {
                vendor_credit_ids: [props.vendorCredit.id],
            });
        }
    }

    function rejectCredit() {
        if (confirm('Are you sure you want to reject this vendor credit?')) {
            router.patch(route('vendor-credits.reject'), {
                vendor_credit_ids: [props.vendorCredit.id],
            });
        }
    }

    function unrejectCredit() {
        if (confirm('Are you sure you want to un-reject this vendor credit?')) {
            router.patch(route('vendor-credits.unreject'), {
                vendor_credit_ids: [props.vendorCredit.id],
            });
        }
    }

    function deleteVendorCredit() {
        if (confirm('Are you sure you want to delete this vendor credit?')) {
            router.delete(route('vendor-credits.destroy', props.vendorCredit.id));
        }
    }
</script>