<template>
    <Head :title="`Message - Vendor Credit Request ${props.vendorCreditRequestMessage.vendorCreditRequest.display_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credit-requests.index')" class="breadcrumb-link">Vendor Credit Requests</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="route('vendor-credit-requests.show', props.vendorCreditRequestMessage.vendorCreditRequest.id)" class="breadcrumb-link">
                Vendor Credit Request {{ props.vendorCreditRequestMessage.vendorCreditRequest.display_id }}
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Message</span>
        </nav>
    </Teleport>

    <!-- Page Header -->
    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ props.vendorCreditRequestMessage.vendorCreditRequest.vendor.name }}
                </h1>

                <p class="text-sm font-medium text-gray-500">
                    <span class="text-gray-900"> Vendor Credit Request Message</span>

                    <span> created on </span>

                    <time :datetime="props.vendorCreditRequestMessage.created_at">
                        {{ $filters.format_date(props.vendorCreditRequestMessage.created_at) }}
                    </time>
                </p>
            </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <inertia-link
                v-if="props.vendorCreditRequestMessage.deleted_at === null && props.vendorCreditRequestMessage.vendorCreditRequest.status !== 'Closed'"
                :href="route('vendor-credit-request-messages.edit', props.vendorCreditRequestMessage.id)"
                class="btn btn-gray"
            >
                Edit
            </inertia-link>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="vendor-credit-request-message-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="vendor-credit-request-message-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor Credit Request Message</p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Request #</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ props.vendorCreditRequestMessage.vendorCreditRequest.display_id }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Created By</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ props.vendorCreditRequestMessage.employee.name }}</dd>
                            </div>
                            <div v-if="props.vendorCreditRequestMessage.sent_to" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Sent To</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ props.vendorCreditRequestMessage.sent_to }}</dd>
                            </div>
                            <div v-if="props.vendorCreditRequestMessage.sent_at" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Sent At</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date_time(props.vendorCreditRequestMessage.sent_at) }}</dd>
                            </div>
                            <div v-if="props.vendorCreditRequestMessage.received_at" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Received At</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date_time(props.vendorCreditRequestMessage.received_at) }}</dd>
                            </div>
                            <div v-if="props.vendorCreditRequestMessage.file_path" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Image</dt>
                                <a :href="route('documents.preview', {path: props.vendorCreditRequestMessage.file_path})" class="link">
                                    <span class="mt-1 text-sm link">View image</span>
                                </a>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <section aria-labelledby="vendor-info-title" class="md:col-start-3 col-span-1">
            <div class="bg-white shadow sm:rounded-lg sm:px-5 border-gray-100 justify justify-center">
                <div class="py-3">
                    <h2 id="vendor-info-title" class="text-lg font-medium text-gray-900">
                        Vendor Contact Details
                    </h2>
                </div>

                <div class="border-t border-gray-200 py-3 justify justify-center">
                    <dl class="grid grid-cols-1 gap-y-4">
                        <div>
                            <dt class="text-sm font-medium text-gray-500">E-mail Address</dt>
                            <dd class="mt-1 text-sm text-gray-900">{{ props.vendorCreditRequestMessage.vendorCreditRequest.vendor.general_email_address }}</dd>
                        </div>

                        <div>
                            <dt class="text-sm font-medium text-gray-500">Phone #</dt>
                            <dd class="mt-1 text-sm text-gray-900">{{ props.vendorCreditRequestMessage.vendorCreditRequest.vendor.general_phone_number }}</dd>
                        </div>
                    </dl>
                </div>
            </div>
        </section>

        <section v-if="props.vendorCreditRequestMessage.content" aria-labelledby="content-title" class="sm:col-start-1 col-span-3">
            <div class="bg-white px-4 py-3 shadow sm:rounded-lg sm:px-6 border-gray-100">
                <div class="px-1 py-2">
                    <h2 id="content-title" class="text-lg font-medium text-gray-900">Content</h2>
                </div>

                <div class="border-t border-gray-200 px-1 py-2">
                    <dl>
                        <dd class="mt-1 text-gray-900" v-html="props.vendorCreditRequestMessage.content" />
                    </dl>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import { Head } from '@inertiajs/vue3';
    import { ref, onMounted, inject } from 'vue';
    import { filters as $filters } from "@/Shared/Utils/Filters";

    // Inject
    const route = inject('route')

    // Props
    const props = defineProps({
        vendorCreditRequestMessage: {
            type: Object,
            required: true,
        },

        errors: {
            type: Object,
            default: () => ({}),
        },
    });

    // State
    const mounted = ref(false);
    const errors = ref({...props.errors});

    // Mounted
    onMounted(() => {
        mounted.value = true;
    });
</script>